import { useEffect, useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../Css/Addtocart.css';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faCartPlus } from '@fortawesome/free-solid-svg-icons';
import baseURL from '../Environment';
import useUserId from './UseUserId'; // Import the custom hook
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify'; // Import the Bounce transition effect
import { CartContext } from './CartContext';
import { useImageContext } from './ImageContext'; // Import the ImageContext hook
import axios from 'axios';

function Addtocart({  sideBarshow, handleCloseSideNav, ...props }) {

  // State variables
  const [products, setProducts] = useState([]); // Array of products
  const navigate = useNavigate(); // Function for navigating to different routes
  const [totalAmount, setTotalAmount] = useState(0); // Total amount of products
  const [show, setShow] = useState(sideBarshow); // Boolean flag for showing/hiding sidebar
  // Access images from ImageContext
  const images = useImageContext(); // Fetch images using the custom hook
  const getImagePath = (productId) => {
    const object = images.find(obj => obj.id === productId.toString());
    return object && object.imageUrl; // Return the image URL if found
  };

  // Function to handle closing the sidebar
  const handleClose = () => {
    setShow(false);
    handleCloseSideNav(false)
  };

  const userId = useUserId(); // Get the userId from the custom hook
  // Function to navigate to a specific hash and close the sidebar
  const navigateTo = (hash) => {
    handleClose();
    navigate(`/#${hash}`);
  };
    //get cart items api
    const fetchInfo = async () => {
      try {
        if (userId) {
          const res = await fetch(`${baseURL}cart/getcart/${userId}`);
          if (!res.ok) {
            throw new Error('Failed to fetch cart data');
          }
          const data = await res.json();
          const productsWithCount = data.products.map((product) => ({
            ...product,
            price: product.originalPrice * product.quantity,
          }));
          setProducts(productsWithCount);
        } else {
          const cartItems = [];
          // Retrieve cart items from local storage
          const storedItems = JSON.parse(localStorage.getItem('cartItems')) || [];
          // Check if stored items is an array
          if (Array.isArray(storedItems)) {
            storedItems.forEach((item) => {
              if (item) {
                item.price = item.originalPrice * item.quantity;
                cartItems.push(item);
              }
            });
          } else if (storedItems) {
            console.error('Local storage data is not an array:', storedItems);
          }
          setProducts(cartItems);
        }
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    };
    // Fetch cart items and scroll to top when userId changes
    useEffect(() => {
      fetchInfo();
      window.scrollTo(0, 0);
    }, [userId]);

  // Function to handle adding a product to the cart
 const handleAdd = (productData, indexNumber) => {
  const updatedProducts = [...products]; // Make a copy of products array
  const product = updatedProducts[indexNumber];
  const newQuantity = product.quantity + 1;
  // Check if the updated quantity is within allowed limits
  if (newQuantity > 10) return;
  const updatedPrice = (product.originalPrice * newQuantity).toFixed(2);
  // Update product quantity and price
  updatedProducts[indexNumber] = { ...product, quantity: newQuantity, price: updatedPrice };

  // If user is authenticated, update on server
  if (userId) {
    axios.post(`${baseURL}cart/increment/${userId}/${productData.productId}/${productData.id}`, {
      quantity: 1,
    })
      .then(response => {
        if (response.status !== 200) throw new Error('Network response was not ok');
      })
      .catch(error => console.error('Error with axios request:', error));
  } 
  // Else update locally using localStorage
  else {
    const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    const existingIndex = cartItems.findIndex(item => (
      item.productId === productData.productId &&
      item.selectedHeight === productData.selectedHeight &&
      item.selectedCategoryType === productData.selectedCategoryType &&
      item.selectedDimension === productData.selectedDimension &&
      item.selectedCover === productData.selectedCover &&
      item.selectedPackOf === productData.selectedPackOf
    ));

    if (existingIndex !== -1) {
      cartItems[existingIndex].quantity += 1;
    } else {
      cartItems.push({ ...productData, quantity: 1 });
    }
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }
  // Update state with new products list
  setProducts(updatedProducts);
  // Call totalAmountData if it's defined to update the total price
  totalAmountData && totalAmountData();
};


  // Function to handle subtracting a product from the cart
  const handleSubtracts = (productData, indexNumber) => {
    const updatedProducts = [...products]; // Make a copy of products array
    const product = updatedProducts[indexNumber];
    const newQuantity = product.quantity - 1;
  
    // If quantity becomes zero, remove the product
    if (newQuantity <= 0) {
      deleted(indexNumber).then((success) => {
        if (success) {
          // Remove product from the cart locally
          const filteredProducts = updatedProducts.filter((_, idx) => idx !== indexNumber);
          setProducts(filteredProducts);
          
          // Remove item from localStorage
          let cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
          cartItems = cartItems.filter(item => item.id !== productData.id);
          localStorage.setItem('cartItems', JSON.stringify(cartItems));
        }
      });
      return;
    }
  
    // Update product quantity locally
    updatedProducts[indexNumber].quantity = newQuantity;
    setProducts(updatedProducts);
  
    // Handle user authenticated scenario
    if (userId) {
      axios.post(`${baseURL}cart/decrement/${userId}/${productData.productId}/${productData.id}`, {
        quantity: 1
      })
      .then(response => {
        if (response.status !== 200) throw new Error('Network response was not ok');
      })
      .catch(error => console.error('Error with axios request:', error));
    } 
    // Handle unauthenticated scenario (local storage)
    else {
      const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const existingIndex = cartItems.findIndex(item => (
        item.productId === productData.productId &&
        item.selectedHeight === productData.selectedHeight &&
        item.selectedCategoryType === productData.selectedCategoryType &&
        item.selectedDimension === productData.selectedDimension &&
        item.selectedCover === productData.selectedCover &&
        item.selectedPackOf === productData.selectedPackOf
      ));
  
      if (existingIndex !== -1) {
        if (cartItems[existingIndex].quantity > 1) {
          cartItems[existingIndex].quantity -= 1;
        } else {
          cartItems.splice(existingIndex, 1); // Remove item if quantity is 0
        }
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
      }
    }
  
       // Update total amount if function is defined
       if (totalAmountData) {
        totalAmountData();
      }
  };
  

  // Function to handle deleting a product from the cart
  const deleted = async (index, updateLocalState = true) => {
    if (!userId) {
      // If user is not logged in, remove item from local storage directly
      if (index !== null && index !== undefined) {
        // If index is provided, remove item using index
        let cartItems = JSON.parse(localStorage.getItem('cartItems')) || []; // Get all cart items from local storage
        if (Array.isArray(cartItems) && cartItems.length > index) {
          cartItems.splice(index, 1); // Remove the product by index
          localStorage.setItem('cartItems', JSON.stringify(cartItems)); // Update the local storage
        }
      }
      if (updateLocalState) {
        // Assuming 'products' state contains the list of products, update it after deletion
        const updatedProducts = [...products.slice(0, index), ...products.slice(index + 1)];
        setProducts(updatedProducts); // Update products state after deletion
        totalAmountData();  // Recalculate total amount after deleting the product
      }
      return true; // Item removed successfully
    } else {
      // If user is logged in, send a DELETE request to the backend to delete the item
      const productId = products[index].id; // Change 'id' to 'productId'
      try {
        const response = await axios.delete(baseURL + `cart/deletefromcart/${userId}/${productId}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 200 && updateLocalState) {
          // Item deleted successfully from the server
          // Assuming 'products' state contains the list of products, update it after deletion
          const updatedProducts = [...products.slice(0, index), ...products.slice(index + 1)];
          setProducts(updatedProducts); // Update products state after deletion
          totalAmountData(); // Recalculate total amount after deleting the product
        } else if (response.status !== 200) {
          // Handle error response from the server
          console.error('Failed to delete item from server');
        }
        return response.status === 200;
      } catch (error) {
        // Handle network errors
        console.error('Network error:', error);
        return false;
      }
    }
  };


  // Function to navigate to the checkout page
  const navigateToCheckout = () => {
    navigate('/Checkout');
    setShow(false);
    handleCloseSideNav(false);
  }

  // Function to calculate the total amount of the products in the cart
  const totalAmountData = () => {
    let totalPrice = products.map(product => product.price).reduce((acc, val) => acc + Number(val), 0);
    setTotalAmount(totalPrice);
  }

  // Calculate the total amount when the products array changes
  useEffect(() => {
    totalAmountData();
  }, [products]);



  // Function to calculate the discounted price
  const calculateDiscountedPrice = (originalPrice, couponCode) => {
    // Extract the last two digits from the coupon code
    const lastTwoDigits = couponCode.slice(-2);
    // Parse the last two digits as an integer to get the discount percentage
    const discountPercentage = parseInt(lastTwoDigits);
    // Calculate the discount amount
    const discountAmount = (originalPrice * discountPercentage) / 100;
    // Calculate the discounted price
    const discountedPrice = originalPrice - discountAmount;
    return discountedPrice;
  };


  // Function to copy the coupon code to the clipboard
  const copyCouponCode = (couponCode) => {
    navigator.clipboard.writeText(couponCode);
    toast.success('Coupon copied!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      transition: Bounce,
      style: {
        minHeight: '30px', 
      },
    });
  };

  // Access the updateCartItemsCount function from the CartContext
  const { updateCartItemsCount } = useContext(CartContext);

  useEffect(() => {
    // Update the cart items count in the CartContext
    updateCartItemsCount(products.length);
  }, [products]);


  return (
    <>
      {products.length > 0}
      <Offcanvas placement="end" show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='cart_center'>CART ({products.length} items) </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='offcanvas-body'>
          {(products.length > 0) ? (products.map((product, index) => (
            <div key={product.id} className="row">
              <div className="col-lg-5 offcanvas_height">
                <img
                  src={getImagePath(product.productId)} // Use getImagePath to retrieve the local image
                  alt="productImage"
                  className="thumbnailImg"
                  width={100}
                />
                <div className="adding">
                  <button className="subtract-btn" onClick={() => handleSubtracts(product, index)}>
                    -
                  </button>
                  <input type="text" value={product.quantity} className="quantity-input" readOnly />

                  <button className="add-btn" onClick={() => handleAdd(product, index)}>
                    +
                  </button>
                </div>
              </div>
              <div className="heading small col-lg-7">
                <h6 className='small_title'>Livon Comforts</h6>
                <h2 className='cart-title'>{product.title}</h2>
                <ul id="ul">
                  {product.selectedCategoryType !== null && product.selectedCategoryType !== undefined && product.selectedCategoryType !== "" &&
                    <li>
                      <b>Size:</b> {product.selectedCategoryType}
                    </li>
                  }
                  {product.selectedPackOf !== null && product.selectedPackOf !== undefined && product.selectedPackOf !== "" &&
                    <li>
                      {product.selectedPackOf === 1 ? "" : <> <b>{`Pack of ${product.selectedPackOf} Pillow${product.selectedPackOf > 1 ? 's' : ''}`}
                      </b></>}
                    </li>
                  }
                  {product.selectedHeight !== null && product.selectedHeight !== undefined && product.selectedHeight !== "" &&
                    <li>
                      <b>Height:</b> {product.selectedHeight} inches
                    </li>
                  }

                  {product.selectedCover !== null && product.selectedCover !== undefined && product.selectedCover !== "" &&
                    <li>
                      <b>Cover:</b> {product.selectedCover}
                    </li>
                  }
                  <li>
                    <b>Dimensions:</b> {product.selectedDimension}
                  </li>
                </ul>
                <div className='copy-code' onClick={() => copyCouponCode(product.offers.text.match(/\b[A-Z0-9]{6,}\b/g).join(' '))}>
                  <div className='DiscountedPrice'>
                    {product.offers && Object.keys(product.offers).length > 0 && (
                      <p className='discounted-amount'>
                        Grab it at <b>₹{calculateDiscountedPrice(product.originalPrice, product.offers.text.match(/\b[A-Z0-9]{6,}\b/g).join(' ')).toFixed(2)}</b>
                      </p>

                    )}
                  </div>
                  <div className='coupon-code-copy'>
                    {product.offers && Object.keys(product.offers).length > 0 ? (
                      <div>
                        <p><b>Use Coupon</b> {product.offers.text.match(/\b[A-Z0-9]{6,}\b/g).join(' ')}</p>
                      </div>
                    ) : (
                      <p>No offers available.</p>
                    )}
                  </div>
                </div>
                <ToastContainer />
                <div className="deleteBlk">
                  <h6 onClick={() => deleted(index)}> <FontAwesomeIcon icon={faTrashCan} /> Remove</h6>
                  <h4 className='ammount'>₹ {Number(product.originalPrice).toFixed(2)}/-</h4>
                </div>
              </div>
            </div>
          ))) :
            <>
              <div className='text-center mt-5'>
                <div>
                  <div className='cart-empty' >
                    <FontAwesomeIcon icon={faCartPlus} size='2xl' style={{ color: "rgb(171, 209, 201)", }} />
                  </div>
                  <h3 style={{ color: 'rgb(171 209 201)' }}>Your cart is empty.</h3>
                  <p style={{ color: 'rgb(171 209 201)' }}>Kindly continue shop </p>
                </div>
                <div className='back-shoping'>
                  <Button onClick={() => navigateTo('mattress-products')}>SHOP MATTRESS</Button>
                  <Button onClick={() => navigateTo('pillow-products')}>SHOP PILLOW</Button>
                  <Button onClick={() => navigateTo('topper-products')}>SHOP TOPPER</Button>
                  <Button onClick={() => navigateTo('kids-products')}>SHOP BABY/KID'S RANGE</Button>
                </div>
              </div>
            </>}
        </Offcanvas.Body>
        {products.length > 0 && <div className="fixed px-10 sidebar_hight bg-secondary-2 ">
          <div className="d-flex justify-content-between mt-1 md:mt-4 bg-secondary-2">
            <p className="text-sm font-semibold md:text-lg font-montserrat">TOTAL</p>
            <p className="text-lg font-bold total-ammount md:text-3xl font-montserrat">₹ {totalAmount.toFixed(2)} /-</p>
          </div>
          <div className="fixed px-10 sidebar_hight bg-secondary-2 ">
            <div className="text-center">
              <div className="snap_emi_txt">
                <div className="snap-emi-slogan"><span>UPI &amp; Cards Accepted </span>
                </div>
              </div>
            </div>
            <button className="check" onClick={navigateToCheckout}>
              <h6>CHECKOUT</h6>
            </button>
            <div className='d-flex cont-shopp'>
              <Link to="/"><p className="continue-shopping mr-3" onClick={() => handleClose()}>Continue Shopping</p></Link>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1.5em" className="svg-icon"
                height="1.5em">
                <path
                  d="M506.134 241.843l-.018-.019-104.504-104c-7.829-7.791-20.492-7.762-28.285.068-7.792 7.829-7.762 20.492.067 28.284L443.558 236H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h423.557l-70.162 69.824c-7.829 7.792-7.859 20.455-.067 28.284 7.793 7.831 20.457 7.858 28.285.068l104.504-104 .018-.019c7.833-7.818 7.808-20.522-.001-28.314z">
                </path>
              </svg>
            </div>
          </div>
        </div>}
      </Offcanvas>
    </>
  );
}

export default Addtocart;

