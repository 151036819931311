import React from 'react';
import '../Css/AllCertifications.css';
import GotsImage from '../images/GOTS Certificate.jpg';
import GolsImage from '../images/GOLS Certificate.jpg';
import Eco from '../images/ECO Certificate.jpg';
import OEKO from '../images/OEKO-TEX Certificate.jpg';
import USDA from '../images/USDA ORGANIC Certificate.jpg';
import SUSTAINABLE from '../images/SUSTAINABLE FURNISHING Certificate.jpg';
import ORGANIC from '../images/ORGANIC EU Certificate.jpg';
import CARBON from '../images/CARBON NEUTRALISED Certificate.jpg';
const AllCertificates = () => {
  return (
    <div className="certifications">
      <div className="certi-heading">
        <h2 className="fontfamily">Certifications</h2>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="certi-content">
              <h2 className="fontfamily">GLOBAL ORGANIC TEXTILE STANDARD</h2>
              <p>Our Organic Cotton which is used in bedding has Global Organic Textile Standard (GOTS) Certification, guaranteeing it meets the world's standards for organic textiles. This seal ensures the Quality and sustainability of the fabric that is made only from organic fibers. It also ensures that the colors used to dye the fabric are completely natural.</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="certi-image">
              <img src={GotsImage} alt="" />
            </div>
          </div>
        </div>
        <div className="row reverse">
          <div className="col-lg-6">
            <div className="certi-image-1">
              <img src={GolsImage} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="certi-content">
              <h2 className="fontfamily">GLOBAL ORGANIC LATEX STANDARD</h2>
              <p>We use organic Latex in our mattresses and pillows which are certified by the Global Organic Latex Standard (GOLS). This certification ensures that 95% of the materials are organic, and are free from Volatile organic compounds. Because of its natural properties the natural latex mattress is dust resistance, anti-fungal and mite free.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="certi-content">
              <h2 className="fontfamily">ECO-INSTITUT</h2>
              <p>The latex foam we use has the eco-INSTITUT label. This label is like a top-quality certification for latex, and it's a must for getting the GOLS certification, which has the same testing standard. Basically, the eco-INSTITUT label assures that our latex foam is safe and has low emissions. Our latex meets all the requirements for the eco-INSTITUT label, and it's made of 100% natural rubber.</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="certi-image">
              <img src={Eco} alt="" />
            </div>
          </div>
        </div>
        <div className="row reverse">
          <div className="col-lg-6">
            <div className="certi-image-1">
              <img src={OEKO} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="certi-content">
              <h2 className="fontfamily">OEKO-TEX®</h2>
              <p>The STANDARD 100 by OEKO-TEX certification is like a health metric for our products. It's a lab test that ensures our natural latex rubber foam is free from harmful substances. Since 1992, this certification has been like the gold standard for making sure textiles and products, are safe for you.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="certi-content">
              <h2 className="fontfamily">USDA ORGANIC</h2>
              <p>Our natural materials like organic latex and organic cotton are USDA Organic certified. This Certification ensures that our products are manufactured in a sustainable and safe way. The Organic Latex and Organic Cotton we use in our products are GOLS and GOTS certified.</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="certi-image">
              <img src={USDA} alt="" />
            </div>
          </div>
        </div>
        <div className="row reverse">
          <div className="col-lg-6">
            <div className="certi-image-1">
              <img src={SUSTAINABLE} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="certi-content">
              <h2 className="fontfamily">SUSTAINABLE FURNISHING</h2>
              <p>The Sustainable Furnishings Council (SFC) is a group of companies who make, sell, and design furniture. They're all about spreading the word and encouraging eco-friendly practices in the furniture industry. As a member, we believe in balancing the needs of people, the planet, and profits. We aim to share the best ways of doing things responsibly throughout the whole process of making and selling furniture.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="certi-content">
              <h2 className="fontfamily">ORGANIC EU</h2>
              <p>Our Products have earned the Organic EU certification, ensuring they meet strict organic standards. This certification guarantees that the raw materials used in the products are free from nitrogen fertilizers and synthetic pesticides. The Organic EU label is one of the most recognized sustainability label internationally and a high level of trust in customers.</p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="certi-image">
              <img src={ORGANIC} alt="" />
            </div>
          </div>
        </div>
        <div className="row reverse pb-5">
          <div className="col-lg-6">
            <div className="certi-image-1">
              <img src={CARBON} alt="" />
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="certi-content">
              <h2 className="fontfamily">CARBON NEUTRALISED</h2>
              <p>Certified Carbon Neutral, our latex products are produced with a commitment to neutralize their carbon impact. This certification highlights our dedication to sustainability, ensuring that our latex items are environmentally responsible. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllCertificates;
