import React, { useState,useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import './components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import SingleProductDetails from './components/SingleProductDetails';
import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Checkout from './components/Checkout';
import GoogleMap from './components/GoogleMap';
import Accountdetails from './components/Accountdetails';
// import PlacedOrder from './components/PlacedOrder';
import MattressProductList from './components/MattressProductList';
import PillowProducts from './components/PillowProducts';
import TopperProducts from './components/TopperProducts';
import KidsProducts from './components/KidsProducts';
import AllCertificates from './components/AllCertificates';
import useUserId from './components/UseUserId';
import Login from './components/Login';
import QuizQuestions from './components/QuizQuestions';
import WhyBuyLatex from './components/WhyBuyLatex';
import SapTosleep from './components/SapTosleep';
import Reasonstobuylatex from './components/Reasonstobuylatex';
import './Css/AllMediaResponse.css'
function App() {

  const userId = useUserId();
  return (
    <>
      <Header  />
      <Routes>
        <Route
          path="/"
          element={<Home />}
        />
         <Route path="/mattress-product-list" component={MattressProductList} />
        <Route
          path="/product/:category/:subcategory/:id/:title"
          element={<SingleProductDetails />}
        />
        
        <Route
          path="/Checkout"
          element={<Checkout />} // Pass totalPrice as prop
        />
          {userId !==null? (
          <Route path="/account-detail/*" element={<Accountdetails />} />
        ) : (
          <Route path="/account-detail/*" element={<Login />} />
        )}
         <Route path="/mattress-selector" element={<QuizQuestions />} />
        <Route
          path="/orders"
          element={<Accountdetails />}
        />
        <Route path='/certifications' element={<AllCertificates/>} />
        <Route path='/why-buy-latex-mattress' element={<WhyBuyLatex/>}/>
        <Route path='/sap-to-sleep' element={<SapTosleep/>}/>
        <Route path='/why-choose-us' element={<Reasonstobuylatex/>}/>
          <Route
          path="/mattressproducts"
          element={<div style={{ margin: '80px 0px' }}><MattressProductList /></div>} // Add margin to MattressProductList
        />
          <Route path="/pillowproducts"  element={<div style={{ margin: '80px 0px' }}><PillowProducts/></div>} />
          <Route path="/topperproducts"  element={<div style={{ margin: '80px 0px' }}><TopperProducts /></div>}/>
          <Route path="/babykidproducts"  element={<div style={{ margin: '80px 0px' }}><KidsProducts /></div>} />
      </Routes>
      
      <GoogleMap />
      <Footer />
    </>
  );
}

export default App;