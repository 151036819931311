import React, { useState } from 'react'
import '../Css/specifications.css';
import { Container, Row, Col } from 'react-bootstrap';
import scale from '../images/MEDIUM FIRM.png'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import baseURL from "../Environment";
import layer1 from '../images/layer 1.png'
import layer2 from '../images/layer 2.png'
import layer3 from '../images/layer 3.png'
import layer4 from '../images/layer4.png'
import comparison from '../images/VS-01.jpg'
import matressStitching from '../images/livon standard latex mattres 00.1.mp4';
import PerfectComfort from '../images/Perfect Comfort & Back Support -min.jpg';
import Breathable from '../images/Breathable & Natural Cooling.jpg';
import Natural from '../images/NATURALLY SAFE.jpg';
import Hypoallergenic from '../images/Completely Hypoallergenic.jpg';
import ProductBenefits from '../images/MEDIUM FIRM NATURAL LATEX MATTRESS BENEFITS .jpg';
import NeedMoreReasons from '../images/Need More Reasons.jpg';
import HealthyForThePlanet from '../images/Healthy For the Planet.jpg';
import HealthyForYou from '../images/Healthy For You.jpg';
function Specifications({ title }) {
    const [open, setOpen] = React.useState(false); // Controls the visibility of a dialog
    const initialFormValues = {
        fullname: '',
        email: '',
        contactNumber: '',
        whatsappNumber: '',
        message: ''
    };
    // State variables for form values and errors
    const [formValues, setFormValues] = useState(initialFormValues); // Holds the values of the contact form
    const [errors, setErrors] = useState({}); // Holds the validation errors for the contact form
    // Function to handle form input change
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
        // Clear error message when user starts typing again
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    // Function to handle numeric input and allow only numbers
    const handleNumericInput = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const regex = /^[0-9]+$/;
        if (!regex.test(keyValue)) {
            event.preventDefault();
        }
    };

    // Function to handle phone number input change
    const handlePhoneNumberChange = (event) => {
        const { value } = event.target;
        // Clear error message when user starts typing again
        setErrors({
            ...errors,
            contactNumber: ''
        });
        if (value.length <= 10) {
            setFormValues({
                ...formValues,
                contactNumber: value
            });
        }
    };

    // Function to handle whatsapp number input change
    const handleWhatsappNumberChange = (event) => {
        const { value } = event.target;
        // Clear error message when user starts typing again
        setErrors({
            ...errors,
            whatsappNumber: ''
        });
        if (value.length <= 10) {
            setFormValues({
                ...formValues,
                whatsappNumber: value
            });
        }
    };
    // Store the hovered layer and the last hovered layer
    const [hoveredLayer, setHoveredLayer] = useState(1);
    const [lastHoveredLayer, setLastHoveredLayer] = useState(1);

    // Function to handle mouse enter for each layer
    const handleMouseEnter = (layer) => {
        setHoveredLayer(layer);
    };

    // Function to handle mouse leave for each layer
    const handleMouseLeave = () => {
        setLastHoveredLayer(hoveredLayer); // Keep the last hovered layer active
    };
    // Function to handle closing the dialog
    const handleClose = () => {
        setOpen(false);
    };

    // Function to reset form values when the dialog is closed
    const handleCloseDialog = () => {
        setFormValues(initialFormValues);
        handleClose();
        setErrors(false);
    };
    // Function to handle opening the dialog
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const formJson = {
            ...formValues,
            label: title
        };
        const formErrors = {};
        let hasErrors = false;

        // Validate each field
        Object.keys(formValues).forEach(key => {
            if (!formValues[key]) {
                formErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
                hasErrors = true;
            }
        });

        // If there are errors, set them and prevent form submission
        if (hasErrors) {
            setErrors(formErrors);
            return;
        }
        // Send form data to the server
        fetch(baseURL + 'customroute', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formJson)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(data => {
                // Handle successful form submission
                setFormValues(initialFormValues); // Reset form values
                handleCloseDialog();
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    return (
        <div>
            <Container>
                <div className="certifications-content">
                    <h2>Sink Into A Serene Sleep</h2>
                    <p>With Livon comforts medium firm 100% pure organic latex mattress, you not only get to sleep peacefully, but you get health benefits as an add-on due to its all natural origin. Livon COmforts latex matresses are eco-INSTITUT and OEKO- TEX Standard 100 Certified Natural. Our mattress provide perfect ergonomic support for a non interrupted sleep. Rest assured, with us, you and your family are not exposed to any toxic surface.</p>
                </div>
            </Container>
            <div className="product-spl-banner">
                <img src={ProductBenefits} alt="" />
            </div>
            <div className="productPage-benefits">
                <h2> MEDIUM FIRM NATURAL LATEX MATTRESS BENEFITS</h2>
                <Row className='productPage-benefits-contnet d-flex justify-content-center align-items-center 100-vw'>
                    <Col lg={6}>
                        <img src={PerfectComfort} alt="" />
                    </Col>
                    <Col lg={6} className='text-center productPage-benefits-contnet-para'>
                        <h3>Perfect Comfort & Back Support</h3>
                        <p>Our medium firm natural latex mattress has latex density of 90 kg/m³ and, that provides an ideal medium firmness, 7.5 on the scale of 1 to 10. The amazing cushioning and resilient support of latex acts as a big element in providing ideal back support as it gently cradles heavy body parts and the light contouring releases pressure adjacent to the joints and lower back.</p>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center align-items-center 100-vw productPage-benefits-contnet-2'>
                    <Col lg={6} className='text-center productPage-benefits-contnet-para'>
                        <h3>Breathable & Natural Cooling</h3>
                        <p>Our Latex mattresses have pin core holes which is an open cell-structure that regulates air flow and makes it 7 times more breathable. It keeps the surface temperature cool even during hot and humid conditions.</p>
                    </Col>
                    <Col lg={6}>
                        <img src={Breathable} alt="" />
                    </Col>
                </Row>
                <Row className='productPage-benefits-contnet d-flex justify-content-center align-items-center 100-vw'>
                    <Col lg={6}>
                        <img src={Natural} alt="" />
                    </Col>
                    <Col lg={6} className='text-center productPage-benefits-contnet-para'>
                        <h3>NATURALLY SAFE</h3>
                        <p>Because latex is inherently antimicrobial, our toppers keeps mold, mildew, and dust mites at bay. It is anti-fungal and anti-bacterial, in addition to being hypoallergenic. It is safe to use for all age groups.</p>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-center align-items-center 100-vw productPage-benefits-contnet-2'>
                    <Col lg={6} className='text-center productPage-benefits-contnet-para'>
                        <h3>Completely Hypoallergenic</h3>
                        <p>Because latex is inherently antimicrobial, our mattresses keeps mold, mildew, and dust mites at bay. It is anti-fungal and anti-bacterial, in addition to being hypoallergenic. Latex is one of the finest options for those who suffer from respiratory difficulties such as asthma.</p>
                    </Col>
                    <Col lg={6}>
                        <img src={Hypoallergenic} alt="" />
                    </Col>
                </Row>
            </div>
            <div className="layer-animation">
                <Container fluid>
                    <h2>What’s Inside Our Medium Firm Natural Latex Mattresses</h2>
                    <p>Our medium firm 100% natural latex mattresses have solid natural latex foam (4", 6” & 8”, depending on the size you opt) with 90 kg/m³ density and an ILD of 40. It is wrapped in a completely organic cotton inner cover. Outer cover is made of chemical free Tencel fabric that offers a range of features including gentleness of skin and long-lasting softness. </p>
                    <p> **Custom size mattresses may not be a single block and side pasting may be required. Kindly contact us for any clarification</p>
                    <div className=''>
                        <div className="d-flex justify-content-center mt-5 layers-buttons">
                            <div className="layers-inches">
                                <button >4" Natural Latex Mattress</button>
                            </div>
                            <div className="layers-inches">
                                <button >6" Natural Latex Mattress</button>
                            </div>
                            <div className="layers-inches-8">
                                <button>8" Natural Latex Mattress</button>
                            </div>
                        </div>
                    </div>
                    <Row className='layer-mattress'>
                        <Col lg={6}>
                            <div className="relative-container">
                                <div
                                    className="layer-container layer-1"
                                    onMouseEnter={() => handleMouseEnter(1)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <img
                                        src={layer4}
                                        className="layer-image"
                                        alt="layer 1"
                                    />
                                    <div className="layer-indicator">
                                        <div className="indicator-line"></div>
                                        <div className="indicator-circle">
                                            <p>1</p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="layer-container layer-2"
                                    onMouseEnter={() => handleMouseEnter(2)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <img
                                        src={layer3}
                                        className="layer-image"
                                        alt="layer 2"
                                    />
                                    <div className="layer-indicator">
                                        <div className="indicator-line"></div>
                                        <div className="indicator-circle">
                                            <p>2</p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="layer-container layer-3"
                                    onMouseEnter={() => handleMouseEnter(3)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <img
                                        src={layer2}
                                        className="layer-image"
                                        alt="layer 3"
                                    />
                                    <div className="layer-indicator">
                                        <div className="indicator-line"></div>
                                        <div className="indicator-circle">
                                            <p>3</p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="layer-container layer-4"
                                    onMouseEnter={() => handleMouseEnter(4)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <img
                                        src={layer1}
                                        className="layer-image"
                                        alt="layer 4"
                                    />
                                    <div className="layer-indicator">
                                        <div className="indicator-line"></div>
                                        <div className="indicator-circle">
                                            <p>4</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            {hoveredLayer === 1 && (
                                <div className="content-container">
                                    <div className="content">
                                        <h2 className="content-title">TENCEL FABRIC</h2>
                                        <p className="content-description">
                                            The anti-bacterial fabric cover has moisture absorption qualities which is also known for its smooth surface that feels soft and supple on the skin.
                                        </p>
                                        <img
                                            src="https://morning-owl.b-cdn.net/component/Comfort%20Level/TENCEL.jpg"
                                            height="150px"
                                            className="content-image"
                                            alt="layer 1 content"
                                        />
                                    </div>
                                </div>
                            )}

                            {hoveredLayer === 2 && (
                                <div className="content-container">
                                    <div className="content">
                                        <h2 className="content-title">ORGANIC COTTON</h2>
                                        <p className="content-description">
                                            The organic cotton fabric is naturally soft and breathable, providing comfort and moisture-wicking properties for a restful sleep.
                                        </p>
                                        <img
                                            src="https://morning-owl.b-cdn.net/component/Comfort%20Level/TENCEL.jpg"
                                            height="150px"
                                            className="content-image"
                                            alt="layer 2 content"
                                        />
                                    </div>
                                </div>
                            )}

                            {hoveredLayer === 3 && (
                                <div className="content-container">
                                    <div className="content">
                                        <h2 className="content-title">6 INCH LATEX FOAM</h2>
                                        <p className="content-description">
                                            This high-quality latex foam layer provides ultimate support and durability, adapting to your body shape for a comfortable sleep.
                                        </p>
                                        <img
                                            src="https://morning-owl.b-cdn.net/component/Comfort%20Level/COTON.jpg"
                                            height="150px"

                                            className="content-image"
                                            alt="layer 3 content"
                                        />
                                    </div>
                                </div>
                            )}

                            {hoveredLayer === 4 && (
                                <div className="content-container">
                                    <div className="content">
                                        <h2 className="content-title">TANCEL FABRIC BOTTOM</h2>
                                        <p className="content-description">
                                            The fabric bottom layer provides a strong foundation while enhancing the durability and performance of the mattress.
                                        </p>
                                        <img
                                            src="https://morning-owl.b-cdn.net/component/Comfort%20Level/TENCEL.jpg"
                                            height="150px"
                                            className="content-image"
                                            alt="layer 4 content"
                                        />
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='scale text-center'>
                <h2>MATTRESS FIRMNESS SCALE</h2>
                <Container fluid>
                    <Row className=' d-flex'>
                        <Col lg={6} md={9} sm={9}>
                            <div className='cuttom-size-form'>
                                <h3>Require Custom Size?</h3>
                                <p>Choose the firmness that suits you the best and order your custom size natural latex mattress today</p>
                                <ul className='text-start ms-md-5 mt-4'>
                                    <li>Before ordering measure your bed size.</li>
                                    <li>Remove existing mattress and using inch tape/flat ruler, measure length and width of your bed.</li>
                                    <li>Call our sleep experts, if you are still not sure about what size will perfectly fit in to your bed.</li>
                                </ul>
                                <div className='text-start custom-button-form'>
                                    <button onClick={handleClickOpen}>Fill Form</button>
                                    <Dialog
                                        open={open}
                                        onClose={handleCloseDialog}
                                    >
                                        <form >
                                            <h2 className="dialog-title">Custom Size for {title}</h2>
                                            <DialogContent className="dialog-content">
                                                <div className="row">
                                                    <div className="col-lg-4 mt-3">
                                                        <label>Name: </label>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <input type="text" name="fullname" value={formValues.fullname} onChange={handleChange} required />
                                                        {errors.fullname && <span className="error">{errors.fullname}</span>}
                                                    </div>
                                                    <div className="col-lg-4  mt-3">
                                                        <label>Email: </label>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <input type="email" name="email" value={formValues.email} onChange={handleChange} required />
                                                        {errors.email && <span className="error">{errors.email}</span>}
                                                    </div>
                                                    <div className="col-lg-4 mt-3 ">
                                                        <label>Contact Number: </label>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <input type="text" name="contactNumber" value={formValues.contactNumber} onChange={handlePhoneNumberChange} onKeyPress={handleNumericInput} maxLength="10" required />
                                                        {errors.contactNumber && <span className="error">{errors.contactNumber}</span>}
                                                    </div>
                                                    <div className="col-lg-4 mt-3 ">
                                                        <label>Whatsapp Number: </label>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <input type="text" name="whatsappNumber" value={formValues.whatsappNumber} onChange={handleWhatsappNumberChange} onKeyPress={handleNumericInput} maxLength="10" required />
                                                        {errors.whatsappNumber && <span className="error">{errors.whatsappNumber}</span>}
                                                    </div>
                                                    <div className="col-lg-4  mt-3">
                                                        <label>Your Message: </label>
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <textarea name="message" value={formValues.message} onChange={handleChange} required></textarea>
                                                        {errors.message && <span className="error">{errors.message}</span>}
                                                    </div>
                                                </div>
                                                <DialogActions>
                                                    <Button onClick={handleCloseDialog} className="cancel">
                                                        Cancel
                                                    </Button>
                                                    <Button type="button" className="save" onClick={handleSubmit}>
                                                        Submit
                                                    </Button>
                                                </DialogActions>
                                            </DialogContent>
                                        </form>
                                    </Dialog>
                                </div>
                            </div>
                            <div className="mattress-card container-fluid d-flex align-items-center justify-content-center bg-white rounded p-lg-3">
                                <div className="icon-container pe-lg-3 border-end">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="93" height="85" fill="none" viewBox="0 0 153 145">
                                        <path fill="#A3CCC4" d="M44.95 58.746L18.478 98.885H143.16l-26.047-40.139H44.951z"></path>
                                        <path fill="#A3CCC4" d="M18.906 99.311H143.68V125.206H18.906z"></path>
                                        <path fill="#EDF5F4" d="M18.906 125.205H143.68V141.584H18.906z"></path>
                                        <path
                                            fill="#000"
                                            d="M44.316 14.97v2.59h4.536v1.82h-4.536v3.57h-2.268v-9.8h7.406v1.82h-5.138zm6.673-1.82h2.268v9.8h-2.268v-9.8zm10.838 9.8l-1.89-2.73H57.85v2.73h-2.268v-9.8h4.242c.868 0 1.62.144 2.254.433.644.29 1.138.7 1.484 1.232.345.532.518 1.162.518 1.89s-.178 1.358-.532 1.89c-.346.523-.84.924-1.484 1.204l2.198 3.15h-2.436zm-.042-6.245c0-.55-.178-.97-.532-1.26-.355-.298-.873-.448-1.554-.448H57.85v3.416h1.848c.681 0 1.2-.15 1.554-.448.354-.298.532-.718.532-1.26zm13.01 6.244l-.013-5.88-2.884 4.844h-1.022l-2.87-4.718v5.754h-2.128v-9.8h1.876l3.668 6.09 3.612-6.09h1.862l.028 9.8h-2.128zm11.487.112a6.9 6.9 0 01-2.142-.336c-.681-.233-1.218-.532-1.61-.896l.518-1.092c.373.336.85.612 1.428.826.588.206 1.19.308 1.806.308.812 0 1.419-.135 1.82-.406.401-.28.602-.648.602-1.106 0-.336-.112-.606-.336-.812a2.083 2.083 0 00-.812-.49c-.317-.112-.77-.238-1.358-.378-.737-.177-1.335-.354-1.792-.532a3.051 3.051 0 01-1.162-.812c-.317-.373-.476-.872-.476-1.498 0-.522.135-.994.406-1.414.28-.42.7-.756 1.26-1.008s1.255-.378 2.086-.378c.579 0 1.148.075 1.708.224.56.15 1.04.364 1.442.644l-.462 1.12a4.902 4.902 0 00-1.316-.588 4.76 4.76 0 00-1.372-.21c-.793 0-1.39.145-1.792.434-.392.29-.588.663-.588 1.12 0 .336.112.612.336.826.224.206.5.369.826.49.336.112.788.234 1.358.364.737.178 1.33.355 1.778.532.448.178.83.448 1.148.812.326.364.49.854.49 1.47 0 .514-.14.985-.42 1.414-.28.42-.705.756-1.274 1.008-.57.243-1.27.364-2.1.364zm5.84-9.912h1.4v9.8h-1.4v-9.8zm4.333 0h4.13c1.036 0 1.956.206 2.758.616a4.46 4.46 0 011.862 1.736c.448.738.672 1.587.672 2.548 0 .962-.224 1.816-.672 2.562-.438.738-1.059 1.312-1.862 1.722-.802.41-1.722.616-2.758.616h-4.13v-9.8zm4.046 8.582c.794 0 1.489-.154 2.086-.462a3.339 3.339 0 001.4-1.288c.327-.56.49-1.204.49-1.932s-.163-1.367-.49-1.918a3.311 3.311 0 00-1.4-1.302c-.597-.308-1.292-.462-2.086-.462h-2.646v7.364h2.646zm14.633 0v1.218h-7.112v-9.8h6.916v1.218h-5.516v3.01h4.914v1.19h-4.914v3.164h5.712z"
                                        ></path>
                                        <path stroke="#000" strokeWidth="3.992" d="M18.66 125.586V99.198h124.906v26.388H18.66z"></path>
                                        <path
                                            stroke="#000"
                                            strokeWidth="3.992"
                                            d="M18.66 139.009v-13.811h124.906v13.811a3.327 3.327 0 01-3.326 3.327H21.987a3.327 3.327 0 01-3.327-3.327zM143.68 98.774l-24.223-37.63a5.324 5.324 0 00-4.476-2.441H47.366c-1.81 0-3.496.92-4.476 2.442L18.667 98.774"
                                        ></path>
                                        <circle cx="54.525" cy="69.57" r="2.06" fill="#000"></circle>
                                        <circle cx="50.532" cy="78.738" r="2.06" fill="#000"></circle>
                                        <circle cx="46.54" cy="87.906" r="2.06" fill="#000"></circle>
                                        <circle cx="72.29" cy="69.57" r="2.06" fill="#000"></circle>
                                        <circle cx="70.958" cy="78.738" r="2.06" fill="#000"></circle>
                                        <circle cx="69.63" cy="87.906" r="2.06" fill="#000"></circle>
                                        <circle cx="90.056" cy="69.57" r="2.06" fill="#000"></circle>
                                        <circle cx="91.388" cy="78.738" r="2.06" fill="#000"></circle>
                                        <circle cx="92.716" cy="87.906" r="2.06" fill="#000"></circle>
                                        <circle cx="107.822" cy="69.57" r="2.06" fill="#000"></circle>
                                        <circle cx="111.814" cy="78.738" r="2.06" fill="#000"></circle>
                                        <circle cx="115.806" cy="87.906" r="2.06" fill="#000"></circle>
                                    </svg>
                                </div>
                                <p className="card-text ms-3 mb-0">
                                    Crafted for durable support, our latex foam mattress ensures{" "}
                                    <strong>lasting comfort and resilience.</strong>
                                </p>
                            </div>
                        </Col>
                        <Col lg={6} md={3} sm={3}>
                            <img src={scale} alt="" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='text-center mt-5 comparison'>
                <img src={comparison} alt="" />
            </div>
            <div className='DRIFT-INTO-PURE'>
                <div className='drift-content'>
                    <h3>DRIFT INTO PURE, EFFORTLESS SLEEP
                        <br />
                        <span>100% NATURAL LATEX MATTRESS</span>
                    </h3>
                    <p>Responsiveness and breathability of natural latex foam, with the softness of organic cotton and with the stain like feel of natural tencel fabric. Gift yourself the perfect blend of health and comfort.</p>
                </div>
                <Container>
                    <Row className='drift-in'>
                        <Col lg={4} className='d-flex justify-content-center align-items-center flex-column'>
                            <div className="drift-img">
                                <img src={HealthyForYou} alt="" />
                            </div>
                            <p className='drift-title'>Healthy For You</p>
                            <p className='drift-para'>Livon COmforts latex rubber foams are Eco-INSTITUT and OEKO-TEX Standard 100 Certified Natural. These certificates confirm that our mattresses are devoid of hazardous chemicals and are even suitable for infants. Rest assured, with us, you and your family are not exposed to any unsafe surface.</p>
                        </Col>
                        <Col lg={4} className='d-flex justify-content-center align-items-center flex-column'>
                            <div className="drift-img">
                                <img src={HealthyForThePlanet} alt="" />
                            </div>
                            <p className='drift-title'>Healthy For the Planet</p>
                            <p className='drift-para'>Our Natural Latex mattresses are made with pure latex which is extracted from the rubber tree. As the main essence of our mattresses are taken from nature, hence they are completely eco-friendly and biodegradable. What more? Rubber trees actually reduce carbon dioxide in the air by a huge margin. Hence, cultivating them is a huge boon!</p>
                        </Col>
                        <Col lg={4} className='d-flex justify-content-center align-items-center flex-column'>
                            <div className="drift-img">
                                <img src={NeedMoreReasons} alt="" />
                            </div>
                            <p className='drift-title'>Need More Reasons ?</p>
                            <p className='drift-para'>One-third of your day is spent on the bed. We understand that your bed is the safest area in the house for you and your family. That's why we use natural materials to create Eco-Friendly, Non-Toxic, Chemical-Free mattresses and pillows, featuring 100% Natural Dunlop latex. Rest assured you are buying peace of mind with Livon Comforts.</p>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div  className='Warranty-section'>
            <Container fluid>
                <Row>
                    <Col lg={6} className='d-flex d-md-block d-sm-block d-xs-block  justify-content-center align-items-center flex-lg-column warranty-content text-sm-center text-md-center' >
                        <h3>Highly Durable With</h3>
                        <span>15 Years Warranty</span>
                        <p>Livon Comforts natural latex mattresses are highly durable due to its all natural origin. It adapts to your body’s needs as they change with time. By handcrafting each mattress with pure latex foams, we ensure that your new natural mattress will be comfortable, supportive, and long-lasting for years to come.</p>
                    </Col>
                    <Col lg={6}>
                        <video src={matressStitching} loop autoPlay></video>
                    </Col>
                </Row>
            </Container>
            </div>
            <div className='product-page-certification-images'>
                <h2>CERTIFICATIONS</h2>
                <Container fluid>
                    <Row>
                        <Col >
                            <img src="https://mymorningowl.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FCertrificate1.125e69bc.png&w=640&q=75" alt="" />
                        </Col>
                        <Col >
                            <img src="https://mymorningowl.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FCertrificate2.47cf0326.png&w=640&q=75" alt="" />
                        </Col>
                        <Col >
                            <img src="https://mymorningowl.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FCertrificate4.851d3a6b.png&w=640&q=75" alt="" />
                        </Col>
                        <Col >
                            <img src="https://mymorningowl.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2FCertrificate5.a419a5ba.png&w=640&q=75" alt="" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="specifications">
                <Container fluid>
                    <div className="specifications-heading"><h1 className='font_family'>SPECIFICATIONS</h1></div>
                    <div className="box">
                        <div className="head"> <h3 className='head_title font_family'>COMFORT AND FEEL</h3></div>
                        <p className='specifications_para'>Our medium firm 100% natural latex mattresses have natural latex foam ( 4”/ 6”/ 8”, depending on the size you opt) with 90 kg/m³ density and an ILD of 40. It has a comfort level of 7.5 on the scale of 1 to 10 where 1 is the softest and 10 is the highest firmness.</p>
                        <p>** Custom size mattresses may not be a single block and side pasting may be required. Kindly contact us for any clarification</p>
                        <div className="head"> <h3 className='head_title font_family'>WEIGHT</h3></div>
                        <div className="table table-responsive text-center">
                            <div className="table table-responsive text-center">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th></th>
                                            <th>SINGLE</th>
                                            <th>DOUBLE</th>
                                            <th>QUEEN</th>
                                            <th>QUEEN XL</th>
                                            <th>KING</th>
                                        </tr>
                                        <tr>
                                            <th>4 INCH</th>
                                            <td>15kg</td>
                                            <td>20kg</td>
                                            <td>24kg</td>
                                            <td>27kg</td>
                                            <td>30kg</td>
                                        </tr>
                                        <tr>
                                            <th>6 INCH</th>
                                            <td>25kg</td>
                                            <td>33kg</td>
                                            <td>41kg</td>
                                            <td>46kg</td>
                                            <td>50kg</td>
                                        </tr>
                                        <tr>
                                            <th>8 INCH</th>
                                            <td>33kg</td>
                                            <td>44kg</td>
                                            <td>55kg</td>
                                            <td>61kg</td>
                                            <td>66kg</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div className="head" > <h3 className='head_title font_family'>100% NATURAL CERTIFIED LATEX</h3></div>
                        <p className='specifications_para'>Livon Comforts are <a href="">Eco-INSTITUT</a> and <a href="">OEKO- TEX</a> Standard 100 Certified Natural. These certificates are testimony that our mattresses are free of hazardous chemicals and are even suitable for infants. Rest assured, you and your family are completely safe in our all natural mattresses.</p>
                        <div className="head" > <h3 className='head_title font_family'>15 YEAR LIMITED WARRANTY</h3></div>
                        <p className='specifications_para'>Years 1 - 10: Full coverage, Non-Prorated | Years 11-15: Limited Coverage, Prorated</p>
                        <div className="head font_family" > <h3 className='head_title'>SLEEP TRIAL POLICY AND SUCCESS TIPS</h3></div>
                        <p className='specifications_para'>Your 100 nights trial for our latex mattress begins on the day of delivery and it is limited to one mattress per household. Our “Success Tips” help center article provides valuable insights that helps in ensuring, your Livon Comforts experience exceeds the expectations that you have got from us.</p>
                    </div>
                </Container>
            </div>

        </div>
    )
}

export default Specifications;