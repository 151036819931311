import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../Css/Header.css';
import { useNavigate } from 'react-router-dom';
import baseURL from "../Environment";
import AOS from 'aos';
import 'aos/dist/aos.css';

// Step 1: Import all images from the assets directory
const importAllImages = (requireContext) => {
  const images = {};
  requireContext.keys().forEach((item) => {
    const key = item.replace('./', '').replace(/\.[^/.]+$/, ""); // Remove './' and file extension
    images[key] = requireContext(item); // Store the image
  });
  return images;
};

// Step 2: Dynamically import all images from the 'assets' directory
const localImages = importAllImages(require.context('../assets', false, /\.(png|jpe?g|svg)$/));

export default function TopperProducts() {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1440 }, items: 4 },
    laptop: { breakpoint: { max: 1440, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 767 }, items: 2 },
    mobile: { breakpoint: { max: 767, min: 0 }, items: 1 }
  };

  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const fetchInfo = () => {
    return fetch(baseURL + 'productdetails/Resthealthy/toppers')
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const navigateToSingleProductDetails = (data) => {
    const titleWithHyphens = data.title.replace(/\s+/g, '-');
    navigate(`/product/${data.category}/${data.subcategory}/${data.id}/${titleWithHyphens}`);
  };

  // Step 3: Function to get the local image based on the product ID
  const getLocalImage = (productId) => {
    return localImages[`${productId}1`] || ''; // Assuming image is stored as productId + 1
  };

  return (
    <div className='topper_products' id='topper-products'>
      <div className='container-fluid'>
        <div className='mat-heading topper_heading'>
          <h2> Our Toppers Range</h2>
          <p>Make your existing mattress reborn by adding a Natural 100% Organic Latex topper layer and make your sleep time 70% more hygienic!</p>
        </div>
        <Carousel responsive={responsive}>
          {data.map((dataObj, index) => (
            <div className='pcard p_card2 product' key={dataObj.id} onClick={() => { navigateToSingleProductDetails(dataObj) }}>
              <img
                src={getLocalImage(dataObj.id)} // Step 4: Use local image instead of fetched image
                alt='mattress product'
                className='product-image'
              />
              <Card.Body>
                <div className="desc-gap">
                  <Card.Title>{dataObj.title}</Card.Title>
                  <ul>
                    {dataObj.script && dataObj.script.length > 0 ? (
                      dataObj.script.map((description, index) => (
                        <li key={index} className="mattress-description">{description}</li>
                      ))
                    ) : (
                      <li className="mattress-description">No description available.</li>
                    )}
                  </ul>
                </div>
                <Button onClick={() => { navigateToSingleProductDetails(dataObj) }}>Buy Now</Button>
              </Card.Body>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
