import React, {useEffect} from "react";
import '../Css/Header.css';
import BenefitsImage from './../images/Benifits-Yellow-Black-1.jpeg';
import BenefitsImageSec from './../images/Befits-Yellow-Black-2.jpeg';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Benefits (){

    // Initialize AOS library for animations
    useEffect(() => {
        AOS.init({
            duration: 1000, // duration of the animation in milliseconds
        });
    }, []);
    
    return (
        <div className="benefits-section">
         <div className="container benefits">
         <div className="Benefit-heading">
            <h2>BENEFITS OF LATEX</h2>
            <p>Our Mattresses, Pillows and Toppers and made out of 100% Pure, Natural and Organic Latex. As you are aware, Latex has the following benefits over other bedding materials:</p>
        </div>
        <div className="row">
          <div className="col-lg-6">
          <div className="benefits-image"  data-aos="flip-right">
          <img
           src={BenefitsImage}
           alt="BenefitsImage"
          />
          </div>
          </div>
          <div className="col-lg-6">
          <div className="benefits-image"data-aos="flip-left">
          <img
           src={BenefitsImageSec}
           alt="BenefitsImage"
          />
          </div>
          </div>
        </div>
         </div>
        </div>
    );
}
export default Benefits;