import React from 'react';
import './AllCertificates';
import '../Css/Accountdetails.css';
import { Link } from 'react-router-dom';
import  SldeOne from '../images/Artboard 1.jpg';
import  SldeTwo from '../images/Artboard 2.jpg';
import  SldeThree from '../images/Artboard 3.jpg';
import  SldeFour from '../images/Artboard 4.jpg';
import  SldeFive from '../images/Artboard 5.jpg';
import  SldeSix from '../images/Artboard 6.jpg';
import  SldeSeven from '../images/Artboard 7.jpg';
import  SldeEight from '../images/Artboard 8.jpg';
export default function WhyBuyLatex() {
  const handleCall = () => {
    window.location.href = 'tel:+919458419999';
  };
  return (
    <div>
      <div className="relative banner_why_buy_latex">
        <div className=" w-full h-full flex justify-center items-center  text-center ">
          <div className=" items-center  w-3/4 left-10 text-black md:w-1/3 bottom-20 py-6 justify-center p-4 bg-opacity-60 mt-10 drop3  ">
            <h1 className="why-buy-heading">WHY BUY A NATURAL<br /> LATEX MATTRESS</h1>
            <h3 className="why-buy-content font-montserrat font-semibold text-xxs md:text-lg  text-white mt-4">All your doubts and queries are cleared here</h3>
          </div>
        </div>
      </div>
      <div className='why-buy-mattress'>
        <div className='container'> <p>Once you step out to buy a mattress, you are met with innumerable options. We give in to the features, lie on it for few minutes, and take it home. The sad part is, seldom do we acknowledge or research the important factors which determine which mattress should be bought that would benefit both our health, as well as the environment.</p>
          <p className='mt-4'> Mattresses which are most often found in the market, are complete chemical products. In the world, where every conscious individual is striving towards a greener environment, it becomes very necessary to buy thoughtful products which are safe for ourselves, as well as the planet. A natural mattress can be a game-changer in a world where everyone is looking for ways to attain a better and safe lifestyle.</p>
          <h2 className='mt-4'>Wondering if it is the best mattress for you?</h2></div>
      </div>
      <div className='ben-heading'>
        <h2>BENEFITS OF NATURAL LATEX MATTRESS</h2>
      </div>
      <div className='container-fluid why-buy-certifications'>
        <div className='row'>
          <div className='col-lg-6 whyBuy-Image '>
            <img src={SldeOne} />
          </div>
          <div className='col-lg-6'>
            <div className='whyBuy-content'>
              <h3>TOXIN FREE</h3>
              <p> Latex is a 100% natural component obtained from the sap of rubber tree, hence, it is free of toxins. Natural latex do not emit carcinogenic substances.</p></div>
          </div>
          <div className='row section-reverse'><div className='col-lg-6'>
            <div className='whyBuy-content'>
              <h3>HYPOALLERGENIC</h3>
              <p> There is no room for bed bugs and other irritating bacteria on a latex mattress. Latex is antimicrobial, and therefore, is naturally mold and dust mite resistant.</p></div>
          </div>
            <div className='col-lg-6 whyBuy-Image '>
              <img src={SldeTwo} />
            </div></div>

        <div className='row'>  <div className='col-lg-6 whyBuy-Image '>
            <img src={SldeThree} />
          </div>
          <div className='col-lg-6'>
            <div className='whyBuy-content'>
              <h3>HIGHLY BREATHABLE</h3>
              <p>Latex foam has an open cell-structure which regulates air flow like no other mattress foam. It keeps the surface temperature cool even during hot and humid conditions.</p></div>
          </div></div>
         <div className='row section-reverse'>
         <div className='col-lg-6'>
            <div className='whyBuy-content'>
              <h3>NATURAL ORTHOPEDIC BENEFITS</h3>
              <p>Organic mattresses like natural mattress has an amazing ergonomic design which contours heavy body parts and relieves pressure as soon as you lie on it.</p></div>
          </div>
          <div className='col-lg-6 whyBuy-Image '>
            <img src={SldeFour }/>
          </div>
         </div>
          <div className='row'>
          <div className='col-lg-6 whyBuy-Image '>
            <img src={SldeFive} />
          </div>
          <div className='col-lg-6'>
            <div className='whyBuy-content'>
              <h3>NATURAL BOUNCINESS</h3>
              <p>Latex mattress has high responsiveness because of it’s natural bounciness. It retains shape faster than memory foam mattress, and never sags.</p></div>
          </div>
          </div>
        <div className='row section-reverse'>
        <div className='col-lg-6'>
            <div className='whyBuy-content'>
              <h3>NATURAL ORTHOPEDIC BENEFITS</h3>
              <p>Organic mattresses like natural mattress has an amazing ergonomic design which contours heavy body parts and relieves pressure as soon as you lie on it.</p></div>
          </div>
          <div className='col-lg-6 whyBuy-Image '>
            <img src={SldeSix} />
          </div>
        </div>
          <div className='row'>
          <div className='col-lg-6 whyBuy-Image '>
            <img src={SldeSeven} />
          </div>
          <div className='col-lg-6'>
            <div className='whyBuy-content'>
              <h3>NATURAL BOUNCINESS</h3>
              <p>Latex mattress has high responsiveness because of it’s natural bounciness. It retains shape faster than memory foam mattress, and never sags.</p></div>
          </div>
          </div>
       <div className='row section-reverse'>   <div className='col-lg-6'>
            <div className='whyBuy-content'>
              <h3>NATURAL ORTHOPEDIC BENEFITS</h3>
              <p>Organic mattresses like natural mattress has an amazing ergonomic design which contours heavy body parts and relieves pressure as soon as you lie on it.</p></div>
          </div>
          <div className='col-lg-6 whyBuy-Image '>
            <img src={SldeEight} />
          </div></div>
        </div></div>
      <div className="container">
        <div className="row">
          <div className="text-center my-5">
            <img src="https://cdn-icons-png.flaticon.com/512/7589/7589521.png" width="170px" />
            <h3 className="my-3 text-success fw-bold">BUY LATEX MATTRESS ONLINE</h3>
            <h6>Talk to real humans, not robots.</h6>
            <div className="my-4 d-flex callus">
              <Link href="tel:+919458419999">  <button className="callus-1 rounded px-5 py-3 border fw-bold" onClick={handleCall}>CALL US</button> </Link>
              <Link href="mailto:sales@livoncomforts.com"> <button className="callus-2 rounded mx-3 px-5 py-3 p-2 border-0 fw-bold">EMAIL US</button></Link>
              <Link href="tel:+919458419999"><button className="callus-3 rounded px-5 py-3 border-0 fw-bold">LIVE CHAT</button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
