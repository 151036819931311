import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import '../Css/Header.css';
import baseURL from "../Environment";
import AOS from 'aos';
import 'aos/dist/aos.css';

// Step 1: Import all images from the assets directory
const importAllImages = (requireContext) => {
  const images = {};
  requireContext.keys().forEach((item) => {
    const key = item.replace('./', '').replace(/\.[^/.]+$/, ""); // Remove './' and file extension
    images[key] = requireContext(item); // Store the image in the object
  });
  return images;
};

// Step 2: Dynamically import all images in the assets/images directory
const images = importAllImages(require.context('../assets', false, /\.(png|jpe?g|svg)$/));

export default function MattressProductList() {
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1440 }, items: 4 },
    laptop: { breakpoint: { max: 1440, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 767 }, items: 2 },
    mobile: { breakpoint: { max: 767, min: 0 }, items: 1 }
  };

  const [data, setData] = useState([]);
  const navigate = useNavigate();

  // Step 3: Fetch mattress product details from the server
  const fetchInfo = () => {
    return fetch(baseURL + 'productdetails/Resthealthy/mattress')
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch(error => console.error('Error:', error));
  };

  // Fetch mattress product details when the component mounts
  useEffect(() => {
    fetchInfo();
  }, []);

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Navigate to the product details page
  const navigateToSingleProductDetails = (data) => {
    const titleWithHyphens = data.title.replace(/\s+/g, '-');
    navigate(`/product/${data.category}/${data.subcategory}/${data.id}/${titleWithHyphens}`);
  };

  // Initialize AOS (animation on scroll)
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="mattress-products" id='Mattress-Products'>
      <div className='container-fluid'>
        <div className='mat-heading mat_heading' >
          <h2> Our Mattress Range</h2>
          <p>Our natural latex mattresses are entirely eco-friendly and long-lasting. Experience sleeping on cleaner and organic materials.</p>
        </div>
        <Carousel responsive={responsive}>
          {data.map((dataObj, index) => {
            // Step 4: Fetch local image for the product by its ID
            const localImage = images[`${dataObj.id}1`] || '/fallback-image.jpg'; // Fallback if image not found

            return (
              <div className='pcard product' key={dataObj.id} onClick={() => navigateToSingleProductDetails(dataObj)}>
                {dataObj.latestproduct === "new" && <div className="new-product-label new"><p>New</p></div>}
                <img src={localImage} alt='mattress product' className='product-image' />
                <Card.Body>
                  <div className="desc-gap">
                    <Card.Title>{dataObj.title}</Card.Title>
                    <ul>
                      {dataObj.script && dataObj.script.length > 0 ? (
                        dataObj.script.map((description, index) => (
                          <li key={index} className="mattress-description">{description}</li>
                        ))
                      ) : (
                        <li className="mattress-description">No description available.</li>
                      )}
                    </ul>

                  </div>
                  <Button onClick={() => navigateToSingleProductDetails(dataObj)}>Buy Now</Button>
                </Card.Body>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}
