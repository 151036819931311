import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import '../Css/Orderdetails.css';
import baseURL from "../Environment";
import useUserId from './UseUserId'; // Import the custom hook
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify'; // Import the Bounce transition effect
import { useImageContext } from './ImageContext'; // Import the ImageContext hook
import axios from 'axios';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    width: '30%', // Adjust the width as needed
    maxWidth: 'none', // Remove the maximum width
  },

  // Media query for screens smaller than or equal to 768px
  '@media (max-width: 768px)': {
    '& .MuiDialog-paper': {
      width: '90%', // Adjust the width for smaller screens
    },
  },
  // Media query for screens between 769px and 1024px
  '@media (min-width: 769px) and (max-width: 1024px)': {
    '& .MuiDialog-paper': {
      width: '60%', // Adjust the width for screens between 769px and 1024px
    },
  },
  // Media query for screens between 769px and 1024px
  '@media (min-width: 1025px) and (max-width: 1440px)': {
    '& .MuiDialog-paper': {
      width: '40%', // Adjust the width for screens between 769px and 1024px
    },
  },
}));


const CheckoutOrders = ({ sendDataToParent, sendTotalAmountToParent }) => {

  const [totalAmount, setTotalAmount] = useState(0);
  const [products, setProducts] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [coupons, setCoupons] = React.useState([]);
  const [couponInput, setCouponInput] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState(0); // Initialize discounted price state
  const [couponApplied, setCouponApplied] = useState(false); // Initialize coupon applied state
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  //dynamic userId
  const userId = useUserId();
  // Access images from ImageContext
  const images = useImageContext(); // Fetch images using the custom hook
  const getImagePath = (productId) => {
    const object = images.find(obj => obj.id === productId.toString());
    return object && object.imageUrl; // Return the image URL if found
  };


  //order details get
  const fetchInfo = () => {
    const cartItemsKey = 'cartItems'; // Define a key for your cart items

    if (userId) {
      // Function to fetch cart data from server
      const fetchCartData = () => {
        fetch(baseURL + `cart/getcart/${userId}`)
          .then((response) => response.json())
          .then((data) => {
            setProducts(data.products);
          })
          .catch((error) => {
            console.error("Error fetching data: ", error);
          });
      };
      // Fetch data from server if user is logged in
      fetchCartData();

      // Fetch locally stored items
      const cartItems = JSON.parse(localStorage.getItem(cartItemsKey)) || [];
      if (Array.isArray(cartItems)) {
        setProducts(cartItems);
      }

    } else {
      // If user is not logged in, use data from local storage
      const cartItems = JSON.parse(localStorage.getItem(cartItemsKey)) || [];
      if (Array.isArray(cartItems)) {
        // Loop through each item in the array and calculate price
        cartItems.forEach(item => {
          item.price = item.originalPrice * item.quantity; // Calculate price for each item
        });
        setProducts(cartItems); // Set products state with the calculated prices
      }
    }
  };

  useEffect(() => {
    fetchInfo(); // Fetch data regardless of userId state
    setTotalAmount(totalPrice); // Recalculate total amount
  }, [userId]); // Empty dependency array ensures this effect runs only once when the component mounts

  //handle add quantity
  const handleAdd = (productData, indexNumber) => {
    const updatedProducts = [...products]; // Copy the products array
    const product = updatedProducts[indexNumber];
    const updatedCount = product.quantity + 1;

    // Ensure quantity does not exceed 10
    if (updatedCount > 10) return;

    // Update product quantity and price
    updatedProducts[indexNumber] = {
      ...product,
      quantity: updatedCount,
      price: (product.originalPrice * updatedCount).toFixed(2)
    };

    if (userId) {
      // Authenticated user: update on server
      axios.post(`${baseURL}cart/increment/${userId}/${productData.productId}/${productData.id}`, { quantity: 1 })
        .then(response => {
          if (response.status !== 200) throw new Error('Network response was not ok');
          return response.data;
        })
        .catch(error => {
          console.error('There was a problem with your axios request:', error);
        });
    } else {
      // Guest user: update in local storage
      const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
      const existingIndex = cartItems.findIndex(item =>
        item.productId === productData.productId &&
        item.selectedHeight === productData.selectedHeight &&
        item.selectedCategoryType === productData.selectedCategoryType &&
        item.selectedDimension === productData.selectedDimension &&
        item.selectedCover === productData.selectedCover &&
        item.selectedPackOf === productData.selectedPackOf
      );

      if (existingIndex !== -1) {
        cartItems[existingIndex].quantity += 1; // Update quantity
      } else {
        cartItems.push({ ...productData, quantity: 1 }); // Add new product
      }
      localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }

    // Update state
    setProducts(updatedProducts);
    setTotalAmount(totalPrice);
  };

  //handle substract quantity
  const handleSubtracts = (productData, indexNumber) => {
    const updatedProducts = [...products]; // Copy the products array
    const product = updatedProducts[indexNumber];
    const updatedCount = product.quantity - 1;

    if (updatedCount <= 0) {
      // Remove product from cart
      deleted(indexNumber).then(success => {
        if (success) {
          const updatedProductsFiltered = updatedProducts.filter((_, idx) => idx !== indexNumber);
          setProducts(updatedProductsFiltered);
          localStorage.removeItem(productData.id); // Remove from local storage
        }
      });
    } else {
      // Update product quantity
      updatedProducts[indexNumber].quantity = updatedCount;

      if (userId) {
        // Authenticated user: update on server
        axios.post(`${baseURL}cart/decrement/${userId}/${productData.productId}/${productData.id}`, { quantity: 1 })
          .then(response => {
            if (response.status !== 200) throw new Error('Network response was not ok');
          })
          .catch(error => {
            console.error('There was a problem with your axios request:', error);
          });
      } else {
        // Guest user: update local storage
        const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        const existingIndex = cartItems.findIndex(item =>
          item.productId === productData.productId &&
          item.selectedHeight === productData.selectedHeight &&
          item.selectedCategoryType === productData.selectedCategoryType &&
          item.selectedDimension === productData.selectedDimension &&
          item.selectedCover === productData.selectedCover &&
          item.selectedPackOf === productData.selectedPackOf
        );

        if (existingIndex !== -1) {
          if (cartItems[existingIndex].quantity > 1) {
            cartItems[existingIndex].quantity -= 1;
          } else {
            // Remove item if quantity reaches zero
            cartItems.splice(existingIndex, 1);
          }
          localStorage.setItem('cartItems', JSON.stringify(cartItems));
        }
      }
    }

    // Update state
    setProducts(updatedProducts);
    setTotalAmount(totalPrice);
  };
  //handle delete product
  const deleted = (index, updateLocalState = true) => {
    return new Promise((resolve, reject) => {
      if (!userId) {
        // If user is not logged in, remove item from local storage directly
        if (index !== null && index !== undefined) {
          let cartItems = JSON.parse(localStorage.getItem('cartItems')) || []; // Get all cart items from local storage
          if (Array.isArray(cartItems) && cartItems.length > index) {
            cartItems.splice(index, 1); // Remove the product by index
            localStorage.setItem('cartItems', JSON.stringify(cartItems)); // Update the local storage
          }
        }
        // Update cartItemsCount in local storage
        const updatedCartItemsCount = parseInt(localStorage.getItem('cartItemsCount')) - 1;
        localStorage.setItem('cartItemsCount', updatedCartItemsCount.toString());

        if (updateLocalState) {
          // Assuming 'products' state contains the list of products, update it after deletion
          const updatedProducts = [...products.slice(0, index), ...products.slice(index + 1)];
          setProducts(updatedProducts); // Update products state after deletion
          setTotalAmount(totalPrice); // Recalculate total amount after deleting the product
        }
        resolve(true); // Item removed successfully
      } else {
        // If user is logged in, send a DELETE request to the backend to delete the item
        const productId = products[index].id;
        fetch(baseURL + `cart/deletefromcart/${userId}/${productId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(response => {
          if (response.ok && updateLocalState) {
            // Item deleted successfully from the server
            // Assuming 'products' state contains the list of products, update it after deletion
            const updatedProducts = [...products.slice(0, index), ...products.slice(index + 1)];
            setProducts(updatedProducts); // Update products state after deletion
            setTotalAmount(totalPrice);// Recalculate total amount after deleting the product
            // Update cartItemsCount in local storage
            const updatedCartItemsCount = parseInt(localStorage.getItem('cartItemsCount')) - 1;
            localStorage.setItem('cartItemsCount', updatedCartItemsCount.toString());
          } else if (!response.ok) {
            // Handle error response from the server
            console.error('Failed to delete item from server');
          }
          resolve(response.ok);
        }).catch(error => {
          // Handle network errors
          console.error('Network error:', error);
          reject(false);
        });
      }
    });
  };
  //coupen dialog box open
  const handleClickOpen = () => {
    setOpen(true);
  };
  //coupen dialog box close
  const handleClose = () => {
    setOpen(false);
  };

  //coupon get
  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await fetch(
          baseURL + 'coupon'
        );
        const data = await response.json();
        setCoupons(data.coupons);
      } catch (error) {
        console.error('Error fetching coupons:', error);
      }
    };
    fetchCoupons();
  }, []);

  //apply coupon
  const handleApplyCoupon = (coupon, event) => {
    if (event) {
      event.preventDefault(); // Prevent the default form submission behavior
    }
    // Check if a coupon has already been applied
    if (couponApplied) {
      toast.error('Coupon applied already!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
        style: {
          minHeight: '30px',
        },
      });
      return;
    }

    if (couponInput) {
      const existingCoupon = coupons.find((c) => c.coupon === couponInput);
      if (!existingCoupon) {
        toast.error('Invalid coupon code!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
          style: {
            minHeight: '30px',
          },
        });
        setCouponInput('');
        return;
      }
      coupon = existingCoupon;
    }

    // Remove any previously applied coupon
    setDiscountedPrice(0);
    setCouponApplied(false);
    setAppliedCoupon(null);

    // Extract the last two digits from the coupon code
    const lastTwoDigits = parseInt(coupon.coupon.slice(-2));
    // Calculate the discount percentage based on the last two digits
    const discountPercentage = lastTwoDigits;
    // Calculate the discounted amount and price
    const discountedAmount = (totalPrice * discountPercentage) / 100;
    const newDiscountedPrice = totalPrice - discountedAmount;

    // Set the discounted price as the new total amount
    setTotalAmount(newDiscountedPrice);
    setDiscountedPrice(newDiscountedPrice);
    setAppliedCoupon(coupon);
    setCouponApplied(true);
    setOpen(false);

    // Show success toast notification when the coupon is successfully applied
    toast.success(`Coupon applied successfully! You saved ${discountPercentage}%`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      transition: Bounce,
      style: {
        minHeight: '30px',
      },
    });
  };


  //coupon remove
  const handleRemoveCoupon = () => {
    // Reset coupon-related states
    setDiscountedPrice(0);
    setCouponApplied(false);
    setAppliedCoupon(null);
    setTotalAmount(totalPrice);
    setCouponInput(''); // Clear the input field

    // Show toast notification for successful coupon removal
    toast.info('Coupon removed successfully!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      transition: Bounce,
      style: {
        minHeight: '30px',
      },
    });
  };


  //total price calculation
  const totalPrice = products && products.length > 0 ?
    products.map(product => product.originalPrice * product.quantity).reduce((acc, val) => acc + Number(val), 0)
    : 0;

  useEffect(() => {
    setTotalAmount(totalPrice);
  }, [totalPrice])

  //sending products to parent component 
  useEffect(() => {
    sendDataToParent(products);
  }, [products]);
  //sending total price to parent component
  useEffect(() => {
    sendTotalAmountToParent(totalAmount);
  }, [totalAmount]);

  return (
    <>
      <div className="form col-md-6 mt-5 yourorder_details">
        <form className="m-0">
          <h6 className="text-center yourorder">YOUR ORDER</h6>
          <hr />
          <div className="container">
            <div className="row">
              {products?.map((product, index) => (
                <div key={product.id} className="order-products">
                  <div className='row'>
                    <div className='col-md-4 cart-icon'>
                      <img
                        src={getImagePath(product.productId)} // Use getImagePath to retrieve the local image
                        alt="productImage"
                        className="thumbnailImg"
                        width={100}
                      />
                      <div className="adding">
                        <button className="subtract-btn" type='button' onClick={() => handleSubtracts(product, index)}>
                          -
                        </button>
                        <input type="text" value={product.quantity} className="quantity-input" readOnly />
                        <button className="add-btn" type='button' onClick={() => handleAdd(product, index)}>
                          +
                        </button>
                      </div>
                    </div>
                    <div className='col-md-8 title ml-5'>
                      <p className='title-p'>{product.title}</p>
                      <div className="heading dimensions">
                        <div className='cart-sizes1'>
                          {product.selectedCategoryType !== null && product.selectedCategoryType !== undefined && product.selectedCategoryType !== "" &&
                            <p className='line'>
                              <b>Size:</b> {product.selectedCategoryType}
                            </p>
                          }
                          {product.selectedPackOf !== null && product.selectedPackOf !== undefined && product.selectedPackOf !== "" &&
                            <p className='line'>
                              {product.selectedPackOf === 1 ? "" : <> <b>pack of {product.selectedPackOf} pillows</b></>}
                            </p>
                          }
                          {product.selectedHeight !== null && product.selectedHeight !== undefined && product.selectedHeight !== "" &&
                            <p className='line'>
                              <b>Height:</b> {product.selectedHeight} inches
                            </p>
                          }
                          {product.selectedCover !== null && product.selectedCover !== undefined && product.selectedCover !== "" &&
                            <p className='line'>
                              <b>Cover:</b> {product.selectedCover}
                            </p>
                          }
                          <p>
                            <b className='select_dimensions'>Dimensions:</b> {product.selectedDimension}
                          </p>
                        </div>
                        <div className="deleteBlk">
                          <h6 onClick={() => deleted(index)}> <FontAwesomeIcon icon={faTrashCan} /> Remove</h6>
                          <h4 className='ms-auto'>₹ {(product.originalPrice * product.quantity).toFixed(2)}/-</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="total fw-bold">
                <div className="row m-2 mt-3">
                  <div className="col-md-6 subtotal_p">
                    <p>Subtotal</p>
                  </div>
                  <div className="col-md-6 subtotal_price ">
                    <p ><span>₹</span>{totalPrice.toFixed(2)}/-</p>
                  </div>
                  <div className="col-md-6 mt-2 shipping_p">
                    <p className='shipping'>Shipping</p>
                  </div>
                  <div className="col-md-6 mt-2 free_p">
                    <p>FREE</p>
                  </div>
                  {couponApplied ? (
                    <div className="row">
                      <div className="col-md-6 mt-2">
                        <p>Coupon Discount({appliedCoupon.coupon})</p>
                      </div>
                      <div className="col-md-6 mt-2 ps-4">
                        <p>{(discountedPrice - totalPrice).toFixed(2)}/-</p>
                      </div>
                    </div>
                  ) : null}
                  <div className='col-md-6 mt-2'></div>
                  <div className='col-md-6 mt-2 viewoffers_cls'>
                    <h6 className="text-danger viewoffers" onClick={handleClickOpen}>View Offers</h6>
                    <BootstrapDialog
                      onClose={handleClose}
                      aria-labelledby="customized-dialog-title"
                      open={open}
                    >
                      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Available Offers
                      </DialogTitle>
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <DialogContent dividers>
                        {coupons.map((coupon) => (
                          <div
                            key={coupon._id}
                            className='row mt-2'
                          >
                            <Typography gutterBottom className='col-lg-10'>
                              <b>{coupon.coupon}</b> <br />{coupon.description}
                            </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleApplyCoupon(coupon)}
                              className='col-lg-2 offer-apply'
                            >
                              Apply
                            </Button>
                          </div>
                        ))}
                      </DialogContent>
                    </BootstrapDialog>
                  </div>
                  <div>
                    <div>
                      {couponApplied ? (
                        <div className="row">
                          <div className="col-md-6 d-flex mb-3 coupon-applied">
                            <p className="text-danger">Coupon Applied  {appliedCoupon.coupon}</p>
                            <button onClick={handleRemoveCoupon} className='coupon-remove'>Remove</button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    {couponApplied ? null : (
                      <div className='view_offers'>
                        <input
                          type="text"
                          placeholder="Coupon Code"
                          className="w-75 p-2 mb-4 border-0"
                          value={couponInput}
                          onChange={(e) => setCouponInput(e.target.value)}
                          required
                        />
                        <button
                          className="coupon"
                          onClick={(event) => {
                            if (couponInput.trim() !== '') {
                              handleApplyCoupon(couponInput, event);
                            }
                          }}
                        >
                          APPLY
                        </button>
                      </div>
                    )}
                  </div>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    transition={Bounce}
                  />
                  <hr />
                  <div className="row mx-2 mb-4 total_ammount">
                    <div className="col-md-6 total_cls" style={{ fontSize: '18px' }}>
                      <p>TOTAL</p>
                    </div>
                    <div className="col-md-6 subtotal_ammount">
                      <h4 className='subtotal_ammount'><span>₹</span>{totalAmount.toFixed(2)}/-</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

    </>
  )
}

export default CheckoutOrders;