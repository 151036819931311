import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import "react-multi-carousel/lib/styles.css";
import '../Css/Header.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import baseURL from '../Environment';
// Step 1: Import all images from a specific directory
const importAllImages = (requireContext) => {
  const images = {};
  requireContext.keys().forEach((item) => {
    const key = item.replace('./', '').replace(/\.[^/.]+$/, ""); // Remove './' and file extension
    images[key] = requireContext(item); // Store the image in the object
  });
  return images;
};

// Step 2: Dynamically import all images in the assets/images directory
const images = importAllImages(require.context('../assets', false, /\.(png|jpe?g|svg)$/));

export default function KidsProducts() {
  useEffect(() => {
    // Initialize AOS library for animations
    AOS.init({
      duration: 1000, // duration of the animation in milliseconds
    });
  }, []);

  // Function to navigate between pages
  const navigate = useNavigate();

  // State variable to store the fetched data
  const [data, setData] = useState([]);

  // Simulated function to fetch data based on category (without image URLs)
  const fetchInfo = () => {
    return fetch(baseURL + 'productdetails/Resthealthy/kids Range')
      .then((res) => res.json())
      .then((data) => {
        // Map through data and assign local images
        const updatedData = data.map((product) => {
          const id = product.id; // Assuming 'id' is the unique identifier
          const localImages = [
            images[`${id}1`], // Local image for id + 1
            images[`${id}2`], // Local image for id + 2
            images[`${id}3`]  // Local image for id + 3
          ];
          return {
            ...product,
            imageurl: localImages // Replace API images with local images
          };
        });
        setData(updatedData);
      })
      .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to navigate to single product details page
  const navigateToSingleProductDetails = (data) => {
    const titleWithHyphens = data.title.replace(/\s+/g, '-');
    navigate(`/product/${data.category}/${data.subcategory}/${data.id}/${titleWithHyphens}`);
  };

  return (
    <div className='kids-products' id='kids-products'>
      <div className='container'>
        <div className='kids_heading'>
          <h2> OUR KIDS RANGE</h2>
          <p>Feel the ultimate comfort with our 100% Naturally Certified Latex Kids Mattress and Pillows like never before.</p>
        </div>
        <div className='row'>
          {data.map((dataObj, index) => (
            <div className='col-lg-4' key={index}>
              <div className='card carousel-card kids-carousel' onClick={() => navigateToSingleProductDetails(dataObj)}>
                {dataObj.imageurl && dataObj.imageurl.length > 0 && (
                  <Carousel data-bs-theme="dark">
                    {dataObj.imageurl.map((imageurl, idx) => (
                      <Carousel.Item key={idx} className='kid-images'>
                        <img className="d-block w-100" src={imageurl} alt={`Slide ${idx + 1}`} style={{ height: "400px" }} />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                )}
                <Card.Body>
                  <Card.Title>{dataObj.title}</Card.Title>
                  <ul>
                    {dataObj.script && dataObj.script.length > 0 ? (
                      dataObj.script.map((description, index) => (
                        <li key={index} className="mattress-description">{description}</li>
                      ))
                    ) : (
                      <li className="mattress-description">No description available.</li>
                    )}
                  </ul>

                  <Button className='kids-button' onClick={() => navigateToSingleProductDetails(dataObj)}>Buy Now</Button>
                </Card.Body>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
