import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import '../Css/Profile.css';
import { Alert } from 'react-bootstrap';
import useUserId from './UseUserId'; // Import the custom hook
import baseURL from '../Environment';

const ProfilePage = () => {
  // State variables for address error, success message, and no changes
  const [isAddressError, setIsAddressError] = useState(false); // Indicates if there is an address error
  const [isSuccess, setIsSuccess] = useState(false); // Indicates if the form submission is successful
  const [isNoChanges, setIsNoChanges] = useState(false); // Indicates if there are no changes made in the form
  // State variable for form data
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phoneNumber: ''
  });

  // Custom hook to get the userId
  const userId = useUserId();

  // Function to handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Function to handle form submission
  const handleProfileDetails = async (e) => {
    e.preventDefault();
    // Validation check
    const parameterNames = Object.keys(formData);
    const isEmpty = parameterNames.some(param => !formData[param]);
    if (isEmpty) {
      setIsAddressError(true);
      return;
    } else {
      setIsAddressError(false);
    }

    try {
      const response = await fetch(baseURL + `profile/update/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fullname: formData.fullname,
          email: formData.email
        })
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.errors[0].msg);
      }
      // Update user details state if update is successful
      setFormData(prevState => ({
        ...prevState,
        fullname: responseData.fullname,
        email: responseData.email
      }));
      setIsSuccess(true);
      setIsNoChanges(false);
      setTimeout(() => {
        setIsSuccess(false);
      }, 1000); // Hide success message after 3 seconds
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  // Function to fetch user details from the server
  const fetchUserDetails = async () => {
    try {
      const response = await fetch(baseURL + `profile/user-details/${userId}`, {
        method: 'GET',
      });

      const data = await response.json();
      if (response.ok) {
        // Update form data with user details fetched from the server
        setFormData(data);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  // useEffect to fetch user details when the component mounts or userId changes
  useEffect(() => {
    if (userId !== null) {
      fetchUserDetails();
    }
  }, [userId]);

  return (
    <>
      <div className='profile_page'>
        <div className='mt-4 mb-5'>
          <h2 className='personal-info'>Personal Information</h2>
        </div>
        {isAddressError && <Alert key={'danger'} variant={'danger'} className='w-50' onClose={() => setIsAddressError(false)} dismissible>
          All input fields are required.
        </Alert>}
        {isSuccess && <Alert key={'success'} variant={'success'} className='w-50' onClose={() => setIsSuccess(false)} >
          Changes saved successfully.
        </Alert>}
        <Form className='w-50 prifile_formcls' onSubmit={handleProfileDetails}>
          <Form.Group className="mb-3">
            <Form.Label className='fw-bold'>Full Name</Form.Label>
            <Form.Control type="text" placeholder="Enter name" className='p-3' name="fullname" value={formData.fullname} onChange={handleChange} />
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Label className='fw-bold'>Email address</Form.Label>
            <Form.Control type="email"
              className='p-3'
              placeholder='Enter email'
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="phoneNumber">
            <Form.Label className='fw-bold'>Phone Number</Form.Label>
            <Form.Control type="text" placeholder="Enter phone number" className='p-3' value={formData.phoneNumber} minLength='10' maxLength='10' disabled />
          </Form.Group>
          <div className='save-changes'>
            <button className='sub-changes' onClick={() => {
              if (formData.fullname === '' && formData.email === '') {
                setIsNoChanges(true);
                setIsSuccess(false);
              }
            }}>SAVE CHANGES</button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ProfilePage;
