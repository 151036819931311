import React, {useEffect} from 'react'
import '../Css/Header.css';
import CertificateImage from './../images/Certificates2.jpg';
import certificationImage from './../images/Certificates.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Certifications() {
   // Initialize AOS library for animations
  useEffect(() => {
    AOS.init({
        duration: 1000, 
    });
}, []);

  return (
    <div className='cert-section'>
        <div className="container benefits">
         <div className="Benefit-heading" >
            <h2>CERTIFICATIONS</h2>
            <p>Latex and Cotton used in our products are having the following certifications:</p>
        </div>
        <div className="row">
          <div className="col-lg-6">
          <div className="benefits-image" data-aos="flip-right">
          <img
           src={CertificateImage}
           alt="CertificatonsImage"
          />
          </div>
          </div>
          <div className="col-lg-6">
          <div className="benefits-image"  data-aos="flip-left">
          <img
           src={certificationImage}
           alt="CertificatonsImage"
          />
          </div>
          </div>
        </div>
        </div>
    </div>
  )
}
