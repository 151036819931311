import React, { useEffect, useState } from 'react';
import '../Css/Productinfo.css';
import Stepper from 'react-stepper-horizontal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify'; // Import the Bounce transition effect
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight, faEdit } from '@fortawesome/free-solid-svg-icons';
import useUserId from './UseUserId'; // Import the custom hook
import baseURL from '../Environment';
import delivered from './../images/delivered.png';
import packag from './../images/package.png';
import rotate from './../images/rotate.png';
import shipped from './../images/shipped.png';
import thumbs from './../images/thumbs-up.png';
import { Col, Row, Button, Form } from 'react-bootstrap';
import { Modal } from 'react-bootstrap'; // Add this import
import { useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useImageContext } from './ImageContext'; // Import the ImageContext hook
const ProductInformation = () => {
  const { orderId } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [isCanceled, setIsCanceled] = useState(false);
  const [shipmentStatus, setShipmentStatus] = useState({ currentStatus: 1 }); // Assuming 1 is the initial status
  const [shiprocketOrderId, setShiprocketOrderId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [scans, setScans] = useState([]); // State to store scans
  const [message, setMessage] = useState('');
  const [activePopover, setActivePopover] = useState(null);
  const userId = useUserId();
  const [returnStatus, setReturnStatus] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const images = useImageContext(); // Fetch images using the custom hook
  const getImagePath = (productId) => {
    let object = images.find(obj => obj.id === productId.toString());
    return object && object.imageUrl;
  };

  const [isEditingAddress, setIsEditingAddress] = useState(false); // New state for editing address
  const [addressForm, setAddressForm] = useState({ // New state for address form data
    shipping_customer_name: '',
    shipping_phone: '',
    shipping_address: '',
    shipping_city: '',
    shipping_state: '',
    shipping_country: 'India',
    shipping_pincode: ''
  });

  const steps = [
    { title: 'Ordered', img: packag },
    { title: 'Processing', img: rotate },
    { title: 'Packed', img: thumbs },
    { title: 'Shipped', img: shipped },
    { title: 'Out For Delivery', img: delivered },
    { title: 'Delivered', img: delivered },
  ];

  // Example stages for the return process
  const returnStages = [
    { title: 'Request Return' },
    { title: 'Accept/Decline Request' },
    { title: 'Schedule Pickup' },
    { title: 'Process Refund' },
    { title: 'Acknowledge and Restock' },
  ];


  const fetchOrderDetails = async () => {
    try {
      if (!userId) return;

      const response = await fetch(baseURL + `orders/getorders/${userId}/${orderId}`);
      const data = await response.json();

      setOrderDetails(data);
      setShiprocketOrderId(data.shiprocketOrderId);
      setScans(data.scans);
      // Update the shipment status based on the API response
      setShipmentStatus({ currentStatus: data.status });

      const statusCode = data.status_code;

      const statusMap = {
        1: 0, // ordered
        6: 3,  // 'Shipped'
        7: 5,  // 'Delivered'
        8: 6,  // 'Canceled'
        9: 2,  // 'RTO Initiated'
        10: 4, // 'RTO Delivered'
        // Add other status codes here
      };

      const currentStep = statusMap[statusCode];
      // Update the active step
      setActiveStep(currentStep);
      // Update cancellation state if necessary
      setIsCanceled(statusCode === 8 || data.status === "cancelled");

      // Update address form
      setAddressForm({
        shipping_customer_name: data.deliveryAddress.fullname,
        shipping_phone: data.deliveryAddress.phoneNumber.replace(/^(\+91|91)?\s*/, ''),
        shipping_address: data.deliveryAddress.address,
        shipping_city: data.deliveryAddress.city,
        shipping_state: data.deliveryAddress.state,
        shipping_country: "India",
        shipping_pincode: data.deliveryAddress.pincode
      });
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  // Fetch order details on load
  useEffect(() => {
    fetchOrderDetails();
  }, [orderId, userId]);

  // Webhook integration for push notifications
  useEffect(() => {
    const eventSource = new EventSource(baseURL+'api/webhook');
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data); // Parse the JSON data from the server
      setMessage(data.message); // Update state with the received message
      // If the event data includes status updates, refetch order details
      if (data.status_code) {
        fetchOrderDetails(); // Fetch updated order details
      }
    };
    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error);
    };
    return () => {
      eventSource.close(); // Cleanup on unmount
    };
  }, []);

  useEffect(() => {
  }, [activeStep]);

  const handlePopoverClick = (index) => {
    // If the clicked popover is already active, close it; otherwise, open it
    setActivePopover(activePopover === index ? null : index);
  };
  // Address edit handler
  const handleEditAddress = () => {
    setIsEditingAddress(true);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Address form input change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddressForm((prev) => ({ ...prev, [name]: value }));
  };

  // Submit edited address
  const handleSubmitAddress = async (e) => {
    e.preventDefault();
    const body = JSON.stringify({
      order_id: shiprocketOrderId,
      ...addressForm,
    });
    try {
      const response = await fetch(baseURL + 'orders/shiprocket/updateaddress', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      });
      if (response.ok) {
        const data = await response.json();
        console.log('Address updated successfully:', data);
        toast.success('Address updated successfully!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
          style: {
            minHeight: '30px',
          },
        });
        setIsEditingAddress(false);
        handleCloseModal();
        fetchOrderDetails();
      } else {
        const errorData = await response.json();
        console.error('Error updating address:', errorData);
        toast.error('Error updating address.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
          style: {
            minHeight: '30px', 
          },
        });
      }
    } catch (error) {
      console.error('Network error:', error);
      toast.error('Network error. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
        style: {
          minHeight: '30px', 
        },
      });
    }
  };

  // Scroll to top on component load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // Cancel order function
  const cancelOrder = async () => {
    const orderId = shiprocketOrderId;
    const url = baseURL + 'orders/shiprocket/cancel-order';
    const body = JSON.stringify({ orderId });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Order canceled successfully:', data);
        // Update state to reflect the cancellation
        setShipmentStatus({ currentStatus: 6 }); // Update shipment status to 'Cancelled'
        setIsCanceled(true); // Ensure the state is set correctly

      } else {
        const errorData = await response.json();
        console.error('Error canceling order:', errorData);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };

  // Create a conditional step array based on cancellation status
  const displaySteps = isCanceled
    ? [...steps, { title: 'Cancelled', img: delivered }]
    : steps;


  // Logic to handle reorder (e.g., redirecting to order page)
  const handleReorder = () => {
    // Assuming you want to reorder all products in the order
    if (orderDetails && orderDetails.products.length > 0) {
      orderDetails.products.forEach((product) => {
        // Navigate to the product page using the navigateToSingleProductDetails function
        navigateToSingleProductDetails(product);
      });
    } else {
      console.log('No products available to reorder.');
    }
  };
  // Function to navigate to a single product's details
  const navigateToSingleProductDetails = (data) => {
    // Replace spaces with hyphens in the title
    const titleWithHyphens = data.title.replace(/\s+/g, '-');
    // Navigate to /product/category/id/title-with-hyphens
    navigate(`/product/${data.category}/${data.productId}/${titleWithHyphens}`);
  };

  // Date formatter
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  // Render loading state
  if (orderDetails === null) {
    return <p>Loading</p>;
  }

  // Calculate subtotal
  const subTotal = orderDetails.products
    .map(product => product.originalPrice * product.quantity)
    .reduce((acc, val) => acc + Number(val), 0);

  const handleReturnOrder = async () => {
    const payload = {
      order_id: orderDetails.shiprocketOrderId,
      order_date: orderDetails.createdAt,
      channel_id: "5000864",
      pickup_customer_name: orderDetails.deliveryAddress.fullname,
      pickup_last_name: orderDetails.deliveryAddress.fullname,
      company_name: "LIVON COMFORTS LLP",
      pickup_address: orderDetails.deliveryAddress.address,
      pickup_address_2: orderDetails.deliveryAddress.address,
      pickup_city: orderDetails.deliveryAddress.city,
      pickup_state: orderDetails.deliveryAddress.state,
      pickup_country: "India",
      pickup_pincode: orderDetails.deliveryAddress.pincode,
      pickup_email: orderDetails.deliveryAddress.email,
      pickup_phone: orderDetails.deliveryAddress.phoneNumber.replace(/^\+91/, ''),
      pickup_isd_code: "91",
      shipping_customer_name: "LIVON COMFORTS LLP",
      shipping_last_name: "LIVON COMFORTS LLP",
      shipping_address: "primary",
      shipping_address_2: "primary",
      shipping_city: "Hyderabad",
      shipping_country: "India",
      shipping_pincode: '500034',
      shipping_state: "Telangana",
      shipping_email: "accounts@livoncomforts.com",
      shipping_isd_code: "91",
      shipping_phone: '9458419999',
      order_items: orderDetails.products.map(product => ({
        name: product.title,
        // qc_enable: true,
        qc_product_name: product.title,
        sku: product.productId,
        units: product.quantity,
        selling_price: product.originalPrice,
        discount: product.originalPrice - subTotal,
        qc_brand: "LIVON COMFORTS LLP",
        // qc_product_image: getImagePath(product.productId)
      })),
      payment_method: orderDetails.paymentMethod,
      sub_total: subTotal,
      total_discount: "0",
      length: 11,
      breadth: 11,
      height: 11,
      weight: 0.5
    };
    console.log(payload, "Return Payload")
    try {
      const response = await fetch(baseURL + 'orders/shiprocket/return-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to return/exchange order');
      }

      const data = await response.json();
      console.log('Return/Exchange order response:', data);
      // Increment return status upon success
      setReturnStatus(prevStatus => Math.min(prevStatus + 1, returnStages.length - 1));

      // Handle success response
    } catch (error) {
      console.error('Error returning/exchanging order:', error);
      // Handle error response
    }
  };

  return (
    <div className='mb-5' style={{ maxHeight: '200vh' }}>
      {orderDetails ? (
        <div className='account-detials_page'>
          <div className='container orders_row'>
            <div className='order-page mb-4'>
              <h2 style={{ color: 'rgb(160 167 167)' }}> <Link to='/account-detail' style={{ color: 'rgb(156 170 170)' }}> Orders </Link>
                <FontAwesomeIcon icon={faAnglesRight} style={{ color: "rgb(156 170 170)" }} /> Order Details</h2>
            </div>
            <div className='py-2'>Order Details</div>
            <Row md={3} lg={4} className='pt-3'>
              <Col className='order_details_font'> ORDER NUMBER </Col>
              <Col className='fw-bold order_details_font'>  {orderDetails.orderId} </Col>
            </Row>
            <Row md={3} lg={4} className='pt-3'>
              <Col className='order_details_font'> ORDER PLACED </Col>
              <Col className='fw-bold order_details_font'> {formatDate(orderDetails.createdAt)} </Col>
            </Row>
            <Row md={3} lg={4} className='pt-3'>
              <Col className='order_details_font'> PAYMENT TYPE </Col>
              <Col className='fw-bold order_details_font'> {orderDetails.paymentMethod} </Col>
            </Row>
            <Row md={3} lg={4} className='pt-3'>
              <Col className='order_details_font'> PAYMENT I'D </Col>
              <Col className='fw-bold order_details_font'> {orderDetails.paymentId} </Col>
            </Row>
          </div>
          <div className='hh-grayBox'>
            <div className='stepper-container d-flex'>
              {displaySteps.map((step, index) => {
                const isStepCompleted = index <= activeStep;
                const isCancelledStep = isCanceled && index === displaySteps.length - 1;
                // Get relevant scans for this step (if any)
                const stepScans = scans && scans.length > 0 ? scans : [];
                // Define the popover with dynamic scan content
                const popover = (
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Body>
                      {stepScans.length > 0 ? (
                        stepScans.map((scan, i) => (
                          <div key={i} className="scan-info">
                            <strong>Location:</strong> {scan.location}<br />
                            <strong>Date:</strong> {scan.date}<br />
                            <strong>Activity:</strong> {scan.activity}<br />
                            <strong>Status:</strong> {scan.status}<br />
                          </div>
                        ))
                      ) : (
                        <p>No scans available for this step.</p>
                      )}
                    </Popover.Body>
                  </Popover>
                );

                return (
                  <OverlayTrigger
                    trigger="click"
                    key={index}
                    placement="bottom"
                    show={activePopover === index} // Show only if it's the active popover
                    overlay={popover}
                    onToggle={() => handlePopoverClick(index)} // Toggle popover on click
                  >
                    <div
                      className={`order-tracking ${isStepCompleted ? 'completed' : ''} ${isCancelledStep ? 'cancelled' : ''}`}
                    >
                      <div className='is-complete' />
                      <p>
                        <img src={step.img} alt={step.title} className='tracking-image' />
                        <br />
                        {step.title}
                      </p>
                    </div>
                  </OverlayTrigger>
                );
              })}
            </div>
            <div className='d-flex justify-content-between mt-5'>
              <div>
                {orderDetails.returnstatus === 'RETURN PENDING' ? (
                  <div style={{ padding: '20px' }}>
                    <h5 className='mb-5'>Return Status</h5>
                    <Stepper
                      steps={returnStages.map((stage) => ({
                        title: <h6>{stage.title}</h6>,
                      }))}
                      activeStep={returnStatus}
                      completeBarColor="#4CAF50"
                      completeTitleColor="#4CAF50"
                      activeColor="#4CAF50"
                      circleTop={0}
                    />
                  </div>
                ) : (
                  orderDetails.status_code === 7 && !isCanceled && (
                    <button className='ms-3 btn-outline-success text-success btn-rounded' onClick={handleReturnOrder}>
                      Return Order
                    </button>
                  )
                )}
              </div>
              <div className=''>
                <div className='d-flex mb-5'>
                  {isCanceled ? (
                    <>
                      <p className='mb-0 cancel-order-text'>Order canceled successfully!</p>
                      <button className='ms-3 btn-outline-success text-success btn-rounded' onClick={handleReorder}>Reorder</button>
                    </>
                  ) : (
                    <>
                      {/* Conditionally display the cancellation message only if the order can be canceled */}
                      {(orderDetails.status_code === 1 || orderDetails.status_code === 6 || orderDetails.status_code === 9) && (
                        <p className='mb-0 cancel-order-text'>Cancellation Available till Shipping!</p>
                      )}
                      {/* Show Cancel Order button only if the status code allows it */}
                      {(orderDetails.status_code === 1 || orderDetails.status_code === 6 || orderDetails.status_code === 9) && !isCanceled && (
                        <button className='ms-3 btn-outline-danger text-danger btn-rounded cancel-order-button' onClick={cancelOrder}>
                          Cancel Order
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {orderDetails.products.map((product, index) => (
            <div key={product._id} className='container shoping-item' style={{ padding: '30px 40px 10px 40px', marginBottom: index < orderDetails.products.length - 1 ? '20px' : '0' }}>
              <div className='row main-2'>
                <div className='col-md-3'>
                  <div>
                    <img src={getImagePath(product.productId)} alt="productImage" className="thumbnailImg" width={100} />
                  </div>
                </div>
                <div className='col-md-8 product-info_productinfo'>
                  <h2>{product.title}</h2>
                  <div className='product-info d-flex mt-3'>
                    {product.selectedCategoryType && <p className='line'>Size : <span className='text-dark'> {product.selectedCategoryType} </span> </p>}
                    {product.selectedHeight && <p className='line'>Height: <span className='text-dark'>{product.selectedHeight}</span></p>}
                    {product.selectedPackOf && <p className='line'>Pack of pillows : <span className='text-dark' > {product.selectedPackOf} </span> </p>}
                    {product.selectedCover && <p className='line'>Cover : <span className='text-dark'> {product.selectedCover} </span> </p>}
                    <p>Dimensions : <span className='text-dark'> {product.selectedDimension} </span></p>
                  </div>
                  <div className='quantity my-3'>
                    <p>Qty: <span className='text-dark'> {product.quantity} </span></p>
                  </div>
                </div>
                <div className='d-flex product-ammount'>
                  <div>
                    <p className='mt-3 product-ammount_product-price'>Product Price :</p>
                  </div>
                  <div>
                    <p className='product-price mt-3'>₹{(product.quantity * product.originalPrice).toFixed(2)}/-</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className='container my-4 payment-info' style={{ fontFamily: 'Montserrat' }}>
            <div className='sub-paymentinfo'>
              <h2 className='text payment-para'> Payment Information </h2>
            </div>
            <div className='d-flex payment-Information'>
              <div className='sub-total'>
                <p className='sub-ammount'> Sub Total : </p>
              </div>
              <div>
                <p className='product-price total-price'> ₹{subTotal.toFixed(2)}/- </p>
              </div>
            </div>
            <div className='d-flex payment-Information'>
              <div className='sub-total'>
                <p className='sub-ammount'> Discount : </p>
              </div>
              <div>
                <p className='product-price total-price'>{(orderDetails.totalPrice - subTotal).toFixed(2)}/- </p>
              </div>
            </div>
            <div className='d-flex product-ammountdetails'>
              <div>
                <p className='product-ammountdetails_para'> Total Amount : </p>
              </div>
              <div>
                <p className='product-price'>₹{orderDetails.totalPrice.toFixed(0)}/- </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading</p>
      )}
      <ToastContainer />
      {orderDetails.deliveryAddress && (
        <div>
          <div>
            <div className='latest-address'>
              <div className='delivery-address mt-1'>
                <div className='delivery-item d-flex'>
                  <h2 className='text2'>Delivery Address</h2>
                  <FontAwesomeIcon icon={faEdit} onClick={handleEditAddress} style={{ cursor: 'pointer' }} className='ms-auto' />
                </div>
                <div className='delivery-location'>
                  <p className='location'>{orderDetails.deliveryAddress.fullname}</p>
                  <p className='location'>{orderDetails.deliveryAddress.phoneNumber}, {orderDetails.deliveryAddress.email}</p>
                  <p className='location'>{orderDetails.deliveryAddress.address}, {orderDetails.deliveryAddress.city}, {orderDetails.deliveryAddress.state} - {orderDetails.deliveryAddress.pincode}</p>
                </div>
              </div>
            </div>
            {isEditingAddress && (
              <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Edit Delivery Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form >
                    <Form.Group controlId="formCustomerName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="shipping_customer_name"
                        value={addressForm.shipping_customer_name}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formPhone">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        name="shipping_phone"
                        value={addressForm.shipping_phone}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formAddress">
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        name="shipping_address"
                        value={addressForm.shipping_address}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formCity">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        name="shipping_city"
                        value={addressForm.shipping_city}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formState">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type="text"
                        name="shipping_state"
                        value={addressForm.shipping_state}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formState">
                      <Form.Label>Country</Form.Label>
                      <Form.Control
                        type="text"
                        name="shipping_country"
                        value={addressForm.shipping_country}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group controlId="formPincode">
                      <Form.Label>Pincode</Form.Label>
                      <Form.Control
                        type="text"
                        name="shipping_pincode"
                        value={addressForm.shipping_pincode}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                      </Button>
                      <Button variant="success" type="button" onClick={handleSubmitAddress} >
                        Update Address
                      </Button>
                    </Modal.Footer>
                  </Form>
                </Modal.Body>
              </Modal>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductInformation;
